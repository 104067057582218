import * as React from 'react';
import {AppBar, UserMenu} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {useMediaQuery} from "@material-ui/core";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const CustomUserMenu = props => (
    <UserMenu {...props}>
    </UserMenu>
);

const CustomAppBar = props => {
    const classes = useStyles();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            {!isSmall &&
                <Typography
                    variant="h5"
                    color="secondary"
                >ASO CMS</Typography>
            }
            {!isSmall &&
                <span className={classes.spacer}/>
            }
        </AppBar>
    );
};

export default CustomAppBar;
