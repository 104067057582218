import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box} from '@material-ui/core';
import {Button, useDataProvider} from 'react-admin';
import {format, isBefore, startOfMonth, subMonths} from 'date-fns';
import {ResponsivePie} from '@nivo/pie';
import {pt} from 'date-fns/esm/locale'
import CardWithIcon from "./CardWithIcon";
import HearingIcon from '@material-ui/icons/Hearing';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0
    dataWithArc.forEach(datum => {
        total += datum.value
    })

    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            fill='white'
            style={{
                fontSize: '30px',
                fontWeight: 600
            }}
        >
            {total}
        </text>
    )
}

export const ReferralsChart = () => {
    const dataProvider = useDataProvider();

    const [referralsData, setReferralsData] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(0);

    useEffect(() => {
        dataProvider.create(`applications/referrals/`, {
            data: {
                raw_request: "GET",
            }
        }).then((result) => {
            const start_date = subMonths(startOfMonth(new Date()), 12);
            const processed_data = JSON.parse(result.data.body).reduce((monthly_data, month_item) => {
                if (!isBefore(start_date, new Date(month_item.month)))
                    return monthly_data;
                const date = format(new Date(month_item.month), 'MMM', {locale: pt});
                if (monthly_data.length === 0 || monthly_data[monthly_data.length - 1].date !== date) {
                    monthly_data.push({
                        date: date,
                        stats: []
                    });
                }
                monthly_data[monthly_data.length - 1].stats.push({
                    type: month_item.referral_type ? month_item.referral_type : "?",
                    count: month_item.referral_count
                })
                return monthly_data;
            }, []);
            setReferralsData(processed_data);
            setCurrentMonth(processed_data.length - 1);
        });
    }, [dataProvider, setReferralsData]);

    if (!referralsData || !referralsData[currentMonth]) return null;

    return (
        <>
            <CardWithIcon
                icon={HearingIcon}
                to="/applications"
                title="Candidaturas - referrals"
                subtitle={referralsData[currentMonth].date}
            />
            <Box>
                <Box height={250}>
                    <ResponsivePie
                        data={referralsData[currentMonth].stats}
                        id="type"
                        value="count"
                        margin={{ top: 30, right: 0, bottom: 30, left: 0 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.2
                                ]
                            ]
                        }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#fff"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    2
                                ]
                            ]
                        }}
                        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
                    />
                </Box>
                <Box textAlign='center' height={40}>
                    <Button color="default" onClick={() => setCurrentMonth(currentMonth - 1)} disabled={currentMonth <= 0}>
                        <ArrowBackIcon />
                    </Button>
                    <Button color="default" onClick={() => setCurrentMonth(currentMonth + 1)} disabled={currentMonth >= referralsData.length - 1}>
                        <ArrowForwardIcon />
                    </Button>
                </Box>
            </Box>
        </>
    );
};
