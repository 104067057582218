import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import {DonationsEdit} from './edition';
import DonationsList from "./list";

const Donations = {
    name: "donations",
    list: DonationsList,
    edit: DonationsEdit,
    icon: MonetizationOnIcon,
};

export default Donations;