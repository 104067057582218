import * as React from 'react';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {DashboardMenuItem, MenuItemLink, usePermissions,} from 'react-admin';
import StarsIcon from '@material-ui/icons/Stars';
import FaceIcon from '@material-ui/icons/Face';
import BuildIcon from '@material-ui/icons/Build';

import SubMenu from './SubMenu';
import iterations from '../iterations';
import courses from '../courses';
import staff_users from '../staff_users';
import staff_users_contacts from '../staff_users_contacts';
import application_specs from '../application_specs';
import application_submissions from '../application_submissions';
import applications from '../applications';
import participants from '../participants';
import users from '../users';
import sent_emails from '../sent_emails';
import tasks from '../tasks';
import sessions from '../sessions';
import donations from '../donations';


const Menu = ({ dense = false }) => {
    const {permissions: perms, loaded} = usePermissions();

    useSelector((state) => state.theme); // force rerender on theme change
    const classes = useStyles();


    const MenuSection = ({menuItems, ...props}) => {
        const [menuState, setMenuState] = useState(true);

        const filteredMenuItems = menuItems.filter(menuItem => {
            const {resource} = menuItem;

            const allowedViews = Object.keys(perms.getAllowedViews(resource))

            return allowedViews.includes("list");
        })

        const MenuSectionItem = ({resource, ...props}) => (
            <MenuItemLink
                {...props}
                dense={dense}
                leftIcon={<resource.icon />}
                to={`/${resource.name}`}
            />
        );

        if (filteredMenuItems.length === 0)
            return null;

        if (filteredMenuItems.length === 1)
            return <MenuSectionItem {...filteredMenuItems[0]} />

        return <SubMenu
            dense={dense}
            handleToggle={() => setMenuState(!menuState)}
            isOpen={menuState}
            {...props}
        >
            {
                filteredMenuItems.map(props => <MenuSectionItem {...props} key={props.resource.name}/>)
            }
        </SubMenu>
    }


    return (!loaded ? null :
        <div className={classes.root}>
            {' '}
            {perms && perms.isAdmin() &&
                <DashboardMenuItem />
            }
            <MenuSection
                icon={<courses.icon />}
                name="Cursos"
                menuItems={[
                    {
                        primaryText: "Iterações",
                        resource: iterations,
                    },
                    {
                        primaryText: "Cursos",
                        resource: courses,
                    },
                    {
                        primaryText: "Sessões",
                        resource: sessions,
                    }
                ]}
            />
            <MenuSection
                name="Candidaturas"
                menuItems={[
                    {
                        primaryText: "Especificação",
                        resource: application_specs,
                    },
                    {
                        primaryText: "Submissões",
                        resource: application_submissions,
                    },
                    {
                        primaryText: "Análise",
                        resource: applications,
                    }
                ]}
            />
            <MenuSection
                menuItems={[
                    {
                        primaryText: "Utilizadores",
                        resource: users,
                    }
                ]} />

            <MenuSection
                icon={<StarsIcon />}
                name="Equipa"
                menuItems={[
                    {
                        primaryText: "Contactos",
                        resource: staff_users_contacts,
                    },
                    {
                        primaryText: "Descrições",
                        resource: staff_users,
                    }
                ]}
            />
            <MenuSection
                icon={<FaceIcon />}
                name="Alunos"
                menuItems={[
                {
                    primaryText: "Participantes",
                    resource: participants,
                },
                {
                    primaryText: "Doações",
                    resource: donations,
                }
            ]}
            />
            <MenuSection
                icon={<BuildIcon />}
                name="Manutenção"
                menuItems={[
                    //TODO
                    // {
                    //     primaryText: "Planeador de Cursos",
                    //     resource: courses_planner,
                    // },
                    {
                        primaryText: "Emails enviados",
                        resource: sent_emails,
                    },
                    {
                        primaryText: "Tarefas",
                        resource: tasks,
                    }
                ]}
            />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
    },
}));

export default Menu;
