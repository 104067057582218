import {
    List,
    Datagrid,
    TextField,
    FilterLiveSearch, EmailField
} from 'react-admin';
import * as React from "react";
import {UserTimelineField} from "./UserTimelineField";
import BigPagination from "../components/BigPagination";
import ListCustomActions from "../components/ListCustomActions";
import MergeUsersButton from "./MergeUsersButton";

const searchFilter = [
    <FilterLiveSearch source="q" alwaysOn/>
];

const ResourceList = props => (
    <List {...props} bulkActionButtons={false} filters={searchFilter} pagination={<BigPagination />}
          actions={<ListCustomActions><MergeUsersButton/></ListCustomActions>}
    >
        <Datagrid rowClick="show" expand={<UserTimelineField in_list={true}/>}>
            <EmailField source="email" />
            <TextField source="name" />
            <TextField source="auth_userid" />
            <TextField source="staff_roles_nr" />
            <TextField source="participations_nr" />
            <TextField source="applications_nr" />
        </Datagrid>
    </List>
);



export default ResourceList;