import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useAuth0 } from "@auth0/auth0-react";
import { Button, useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';


export const HostedFile = ({ filename }) => {
    const {
        getAccessTokenSilently
    } = useAuth0();

    const onClick = async () => {
        const url = process.env.REACT_APP_API_URL + "files/download/" + filename;
        const token = await getAccessTokenSilently();

        const result = await fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        const blob = await result.blob();
        const href = window.URL.createObjectURL(blob);

        // "dirty" way but couldn't find a better one
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = href;
        link.setAttribute('type', 'hidden');
        link.setAttribute('target', '_blank');
        link.click();
    }

    return (
        <Button
            label="Abrir ficheiro"
            onClick={onClick}
        >
            <CloudDownloadIcon />
        </Button>
    );
};


export const HostedFileField = ({ source }) => {
    const record = useRecordContext();

    if (!record || !record[source])
        return null;

    return <HostedFile filename={record[source]} />;
}


HostedFileField.defaultProps = { addLabel: true };

HostedFile.propTypes = {
    filename: PropTypes.string
};

HostedFileField.propTypes = {
    source: PropTypes.string.isRequired
};
