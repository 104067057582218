import * as React from "react";
import {useState} from "react";
import {
  Datagrid,
  Filter,
  ListContextProvider,
  Loading,
  Pagination,
  SelectInput,
  TextField,
  Title,
  useGetList,
  usePermissions
} from 'react-admin';
import {Redirect} from "react-router-dom";
// import {makeStyles} from '@material-ui/core/styles';
import moment from "moment";
import {Card,} from '@material-ui/core';
import {months} from "../utils";
//
// const useStyles = makeStyles((theme) => ({
//     root: {
//         maxWidth: 345,
//     },
//     expand: {
//         transform: 'rotate(0deg)',
//         marginLeft: 'auto',
//         transition: theme.transitions.create('transform', {
//             duration: theme.transitions.duration.shortest,
//         }),
//     },
//     expandOpen: {
//         transform: 'rotate(180deg)',
//     },
//     container: {
//         marginTop: "1em"
//     }
// }));

const CoursePlannerPage = () => {
    const {permissions, loading} = usePermissions();
    // const classes = useStyles();

    const [sort, setSorts] = useState({ field: 'min_staff_members', order: 'ASC' });
    const [selectedMonth, setSelectedMonth] = useState(moment.utc().month() + 1 + 1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);

    const {data, ids, total, loading: loadingCourses} = useGetList(
        'courses',
        { page: page, perPage: perPage },
        sort,
        {possible_months_contains: selectedMonth}
    );

    if (loading || loadingCourses) return <Loading />;
    if (!permissions.isAdmin()) return <Redirect to="/" />

    const setFilters = filters => setSelectedMonth(filters.possible_months_contains);
    const setSort = (field, order) => setSorts({ field, order });
    return (
        <ListContextProvider value={{ data, ids, total, page, perPage, setPage, setPerPage, filterValues: {possible_months_contains: selectedMonth}, setFilters, currentSort: sort, setSort, resource: 'courses'}}>
            <div>
                <Title title="Planeador de Cursos" />
                <Filter>
                    <SelectInput label="Mês" source="possible_months_contains" choices={months.map((name, index) => ({id: index + 1, name: name}))} alwaysOn/>
                </Filter>
                <Card>
                    <Datagrid resource="courses">
                        <TextField source="name" />
                        <TextField source="short_slug" />
                        <TextField source="min_staff_members" />
                    </Datagrid>
                </Card>
                <Pagination />
            </div>
        </ListContextProvider>
    );

    // {/*<Grid*/}
    // {/*    container*/}
    // {/*    spacing={2}*/}
    // {/*    direction="row"*/}
    // {/*    justify="flex-start"*/}
    // {/*    alignItems="flex-start"*/}
    // {/*    className={classes.container}*/}
    // {/*>*/}
    // {/*    {*/}
    // {/*        ids.map(id => (*/}
    // {/*            <Grid item xs={12} sm={6} md={3} key={id}>*/}
    // {/*                <CourseCard course={data[id]} />*/}
    // {/*            </Grid>*/}
    // {/*        ))*/}
    // {/*    }*/}
    // {/*</Grid>*/}
    //
};

export default CoursePlannerPage;
