import IterationIcon from '@material-ui/icons/CastForEducation';
import { IterationCreate, IterationEdit } from './edition';
import IterationShow from './show';
import List from "./list";

const Iterations = {
    name: "iterations",
    list: List,
    edit: IterationEdit,
    create: IterationCreate,
    show: IterationShow,
    icon: IterationIcon,
};

export default Iterations;