export const courseOption = course => `${course.name} (${course.slug})`;

export const forumOption = forum => `${forum.name} (${forum.slug})`;

export const discordOption = discord => `${discord.name} (${discord.id})`;

export const edxOption = edx => `${edx.name} (${edx.id})`;

export const getIterationName = (record, showCourse, showProgram) => 
    record ? (
    (showProgram ? `${record.course?.program.slug} / ` : '') +
    (showCourse ? `${record.course?.name} ` : '') + 
    record.iteration_name + 
    (record.shift ? ` (${record.shift})` : '')) : ''
