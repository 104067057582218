import {ReferenceManyField, Show, SimpleShowLayout, TextField, useRecordContext, useResourceContext} from 'react-admin';
import * as React from "react";
import BigPagination from "../components/BigPagination";
import {IterationDatagrid} from "../iterations/list";
import {useMediaQuery} from "@material-ui/core";

const CourseTitle = ({ record }) => {
    return <span>{record ? `${record.name}` : ''}</span>;
};

const Aside = () => {
    const record = useRecordContext();
    const resource = useResourceContext();

    return record ? (
        <SimpleShowLayout record={record} resource={resource}>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="program.name" />
            <TextField source="slug" />
            <TextField source="short_slug" />
        </SimpleShowLayout>
    ) : null;
};

const CourseShow = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <Show
            aside={isSmall ? null : <Aside />}
            {...props}
            component="div"
            title={<CourseTitle/>}
        >
            <>
                {isSmall &&
                    <Aside />
                }
                <ReferenceManyField reference="iterations" target="course_id" link="show" addLabel={false} sort={
                    {field: 'start_date', order: 'desc'}
                } pagination={<BigPagination />}>
                    <IterationDatagrid in_courses={true} permissions={permissions} />
                </ReferenceManyField>
            </>
        </Show>
    );
};

export default CourseShow;