import * as React from "react";
import {Button, useGetManyReference, useNotify, useRecordContext} from "react-admin";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

export const CopyStaffEmails = () => {
    const notify = useNotify();
    const record = useRecordContext();

    const {data, ids, loading, error} = useGetManyReference(
        'staff_users',
        'course_iter_id',
        record.id,
        { page: 1, perPage: 1000 },
        {field: 'name', order: 'asc'},
        {},
        'iterations'
    );

    const copyEmails = () => {
        navigator.clipboard.writeText(ids.map(id => data[id].user.email).join(";"));
        notify(
            'resources.iterations.copy_staff_emails_message',
            'info',
            {})
    }

    return (
        <Button
            label="resources.iterations.copy_staff_emails_button"
            onClick={copyEmails}
            disabled={loading || error}
        >
            <AlternateEmailIcon />
        </Button>
    );
};
