import * as React from 'react';
import {createElement} from 'react';
import {Box, Card, Divider, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';

import cartoucheDark from './cartoucheDark.png';

const useStyles = makeStyles(() => ({
    card: {
        minHeight: 52,
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
    },
    main: () => ({
        overflow: 'inherit',
        padding: 16,
        background: `url(${
            cartoucheDark
        }) no-repeat`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .icon': {
            color: 'inherit',
        },
    }),
    title: {},
}));

const CardWithIcon = (props) => {
    const { icon, title, subtitle, to, children, onClick } = props;
    const classes = useStyles(props);
    return (
        <Card className={classes.card} onClick={onClick}>
            <Link to={to}>
                <div className={classes.main}>
                    <Box width="3em" className="icon">
                        {createElement(icon, { fontSize: 'large' })}
                    </Box>
                    <Box textAlign="right">
                        {subtitle &&
                            <>
                                <Typography
                                    className={classes.title}
                                    color="textSecondary"
                                >
                                    {title}
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    {subtitle || ' '}
                                </Typography>
                            </>
                        }
                        {!subtitle &&
                        <>
                            <Typography variant="h5" component="h2">
                                {title}
                            </Typography>
                        </>
                        }
                    </Box>
                </div>
            </Link>
            {children && <Divider />}
            {children}
        </Card>
    );
};

export default CardWithIcon;