import DescriptionIcon from '@material-ui/icons/Description';
import {StaffUserEdit} from './edition';
import StaffUserList from "./list";

const StaffUsers = {
    name: "staff_users",
    list: StaffUserList,
    edit: StaffUserEdit,
    icon: DescriptionIcon,
    show: null
};

export default StaffUsers;
