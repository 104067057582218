import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box} from '@material-ui/core';
import {useGetList} from 'react-admin';
import {addMonths, endOfMonth, format, startOfMonth, subMonths} from 'date-fns';
import {ResponsiveBar} from '@nivo/bar';
import {pt} from 'date-fns/esm/locale'
import CardWithIcon from "./CardWithIcon";
import PeopleIcon from '@material-ui/icons/People';
import {commonChartOptions} from "./utils";

export const UserCountsChart = () => {
    const oneYearAgoISO = subMonths(startOfMonth(new Date()), 11).toISOString();
    const endNextMonthISO = endOfMonth(addMonths(new Date(), 1)).toISOString();
    const startNextMonthISO = startOfMonth(addMonths(new Date(), 1)).toISOString();

    const { data, ids, loaded } = useGetList(
        'iterations',
        { page: 1, perPage: 1000 },
        { field: 'start_date', order: 'DESC' },
        {
            'start_date_gt': oneYearAgoISO,
            'start_date_lt': endNextMonthISO,
        },
    );

    const [months, setMonths] = useState([]);
    const [nextMonth, setNextMonth] = useState({applications: 0});

    useEffect(() => {
        const courses = ids.map(id => data[id]);

        const coursesByMonth = courses.reduce((ls, course) => {
            const month = startOfMonth(new Date(course.start_date)).toISOString();
            if (!ls[month])
                ls[month] = [];
            ls[month].push(course);
            return ls;
        }, {})

        const compute_percent_change = (month_i, month_arr, type) => {
            if (month_i === 0)
                return null;
            let change = ((month_arr[month_i][type] - month_arr[month_i - 1][type]) / month_arr[month_i - 1][type] * 100).toFixed(2);
            return (change >= 0 ? "+" : '') + change + "%";
        };

        const amountByMonth = Object.keys(coursesByMonth).sort().map(month => {
            return {
                month: month,
                date: format(new Date(month), 'MMM', {locale: pt}),
                courses: coursesByMonth[month].length,
                applications: coursesByMonth[month].reduce((ct, course) => ct + course.applications_nr, 0),
                students: coursesByMonth[month].reduce((ct, course) => ct + course.students_nr, 0),
            };
        }).map((month_data, month_i, month_arr) => {
            return {
                percent_change: {
                    "applications": compute_percent_change(month_i, month_arr, "applications"),
                    "students": compute_percent_change(month_i, month_arr, "students")
                },
                ...month_data
            }
        });

        if (amountByMonth.length > 0 && amountByMonth[amountByMonth.length - 1].month === startNextMonthISO)
            setNextMonth(amountByMonth.splice(-1, 1)[0]);

        setMonths(amountByMonth);

    }, [ids, data, startNextMonthISO]);

    if (!loaded) return null;

    return (
        <>
            <CardWithIcon
                icon={PeopleIcon}
                to="/applications"
                title="Alunos e Candidatos"
                subtitle={nextMonth.applications === 0 ? null : `${nextMonth.applications} candidaturas para ${format(new Date(nextMonth.month), 'MMMM', {locale: pt})}`}
            />
            <Box height={300}>
                <ResponsiveBar
                    {...commonChartOptions}
                    data={months}
                    indexBy="date"
                    keys={['applications', 'students']}
                    groupMode="grouped"
                    colors={{"scheme": "dark2"}}
                    tooltipLabel={x => `${x.data.percent_change[x.id] != null ? x.data.percent_change[x.id] + ' ' : ''}(${x.data.courses} cursos) ${x.id === 'applications' ? 'candidaturas' : 'alunos'}`}
                />
            </Box>
        </>
    );
};
