import CoursePlannerIcon from '@material-ui/icons/SquareFoot';
import Page from "./page";

const Courses = {
    name: "courses-planner",
    list: true,
    page: Page,
    icon: CoursePlannerIcon,
};

export default Courses;
