import BigPagination from "./BigPagination";
import {BulkActionsToolbar, ListBase, ListToolbar, ReferenceManyField, useRecordContext, ListActions} from "react-admin";
import * as React from "react";
import {Children, cloneElement} from "react";

const ReferenceList = ({children, reference, target, filters, filter, bulkActionButtons, actions, record, ...props}) => {
    const recordData = useRecordContext();

    if (!record)
        record = recordData;

    const validFilters = filters ? filters.filter(f => f.props.source !== target) : null;

    return (
        record ?
        <ReferenceManyField
            reference={reference}
            target={target}
            addLabel={false}
            filter={{[target]: record.id, ...filter }}
            record={record}
            {...props}
        >
            <ListBase {...props}>
                {(validFilters || actions) &&
                    <ListToolbar
                        filters={validFilters}
                        actions={actions ? actions : <ListActions /> }
                    />
                }
                {bulkActionButtons && (
                    <BulkActionsToolbar>
                        {bulkActionButtons}
                    </BulkActionsToolbar>
                )}
                {children &&
                    cloneElement(Children.only(children), {
                        hasBulkActions: !!bulkActionButtons,
                    })
                }
                <BigPagination />
            </ListBase>
        </ReferenceManyField>
    : null)
};

export default ReferenceList;