import {Datagrid, List, SelectInput, TextField} from 'react-admin';
import * as React from "react";
import {DateTimeInput} from "../components/date-time-pickers";
import BigPagination from "../components/BigPagination";
import TaskName from "./TaskName";
import TaskShow from "./show";
import {taskChoices} from "./utils";
import LcDateField from "../components/LcDateField";

const searchFilter = [
    <SelectInput source="status" choices={[
        { id: 'SUCCESS', name: 'SUCCESS' },
        { id: 'FAILURE', name: 'FAILURE' },
    ]} />,
    <SelectInput source="name" choices={taskChoices} />,
    <DateTimeInput source="date_done_lt" />,
    <DateTimeInput source="date_done_gt" />
];

const ResourceList = props => (
    <List {...props}
          bulkActionButtons={false}
          sort={{ field: 'date_done', order: 'DESC' }}
          filters={searchFilter}
          pagination={<BigPagination />}
    >
        <Datagrid rowClick="show"
                  expand={<TaskShow />}
        >
            <TaskName source="name" />
            <TextField source="status" />
            <LcDateField source="date_done" showTime={true}/>
        </Datagrid>
    </List>
);



export default ResourceList;