import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import ParticipantsList from "./list";
import ParticipantsShow from "./show";

const Participants = {
    name: "participants",
    list: ParticipantsList,
    show: ParticipantsShow,
    icon: LaptopMacIcon,
};

export default Participants;