import CourseIcon from '@material-ui/icons/School';
import { CourseEdit, CourseCreate } from './edition';
import List from "./list";
import Show from "./show";

const Courses = {
    name: "courses",
    list: List,
    edit: CourseEdit,
    create: CourseCreate,
    show: Show,
    icon: CourseIcon,
};

export default Courses;
