import AssignmentIcon from '@material-ui/icons/AssignmentInd';
import ApplicationSubmissionList from "./list";
import ApplicationSubmissionShow from "./show";

const ApplicationSubmissions = {
    name: "application_submissions",
    list: ApplicationSubmissionList,
    show: ApplicationSubmissionShow,
    icon: AssignmentIcon,
};

export default ApplicationSubmissions;