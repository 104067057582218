import {Toolbar} from 'react-admin';
import ToolbarWithoutDelete from "./ToolbarWithoutDelete";

const ToolbarConditionalDelete = ({condition, ...props}) => {
    if (condition)
        return <Toolbar {...props} />;

    return <ToolbarWithoutDelete {...props} />;
};

export default ToolbarConditionalDelete;