import * as React from 'react';
import { useRecordContext } from 'react-admin';

const TaskName = ({ source}) => {
    const record = useRecordContext();
    let value = record && record[source];

    if (value == null) {
        return null;
    }

    const tString = "tasks."
    const indexT = value.indexOf(tString)
    if (indexT > -1)
        value = value.substring(indexT + tString.length);

    return (
        <span>
            {value}
        </span>
    );
};

TaskName.defaultProps = {
    addLabel: true,
};

export default TaskName;