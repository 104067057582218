import {Datagrid, EmailField, FilterLiveSearch, List, TextField} from 'react-admin';
import * as React from "react";
import {DateTimeInput} from "../components/date-time-pickers";
import BigPagination from "../components/BigPagination";
import LcDateField from "../components/LcDateField";

const searchFilter = [
    <FilterLiveSearch source="q" alwaysOn/>,
    <DateTimeInput source="sent_at_lt" />,
    <DateTimeInput source="sent_at_gt" />
];

const ResourceList = props => (
    <List {...props} bulkActionButtons={false} sort={{ field: 'sent_at', order: 'DESC' }} filters={searchFilter} pagination={<BigPagination />}>
        <Datagrid rowClick="show">
            <TextField source="subject" />
            <EmailField source="to" />
            <EmailField source="cc" />
            <LcDateField source="sent_at" showTime={true}/>
        </Datagrid>
    </List>
);



export default ResourceList;