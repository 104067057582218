import {permissions} from "./permissions";

const rolesStr = "http://treetree2.org/roles";

const getPermissions = (user) => {
    const perms = {
        getRoles: () => {
            const roles = rolesStr in user ? user[rolesStr] : [];
            if (localStorage.getItem("coordinator") === "true")
                roles.push("coordinator")
            return roles;
        },
        isAdmin: () => perms.getRoles().includes("admin"),
        isEditor: () => perms.hasRole("editor"),
        isCoordinator: () => perms.hasRole("coordinator"),
        hasRole: (role) => perms.isAdmin() || perms.getRoles().includes(role),
        canViewResource: (resource) =>
            perms.isAdmin()
            || Object.keys(perms.getAllowedViews(resource)).length > 0,
        getAllowedViews: ({name, icon, ...resource}) =>
            Object.keys(resource)
                .reduce((dict, view) => {
                    const allowedRoles = permissions[name] ? permissions[name][view] : [];
                    if (perms.isAdmin() ||
                        perms.getRoles().some(role =>
                            allowedRoles ? allowedRoles.includes(role) : false
                        ))
                        dict[view] = resource[view]
                    return dict;
                }, {}),
    }

    return perms;
}

const checkCoordinator = (dataProvider) => dataProvider.httpClient('auth/coordinator/').then(({json}) => {
    localStorage.setItem('coordinator', json);
    if (json !== "true")
        throw new Error();
});

const authProvider = ({
                          dataProvider,
                          isAuthenticated,
                          loginWithRedirect,
                          logout,
                          user
                      }) => ({
    login: loginWithRedirect,

    logout: () => {
        if (isAuthenticated) // need to check for this as react-admin calls logout in case checkAuth failed
            return logout({
                returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URL,
                federated: true
            });
        return Promise.resolve()
    },

    checkError: () => ({status}) => {
        if (status === 401 || status === 403)
            return Promise.reject();
        return Promise.resolve();
    },

    checkAuth: () =>
        !isAuthenticated ? Promise.reject() :
            (
                (rolesStr in user && (user[rolesStr].includes("admin") || user[rolesStr].includes("editor"))) ?
                    Promise.resolve() :
                    checkCoordinator(dataProvider)
            ),

    getPermissions: () => Promise.resolve(getPermissions(user)),

    getIdentity: () =>
        Promise.resolve({
            fullName: user.email,
            avatar: user.picture
        })

});

export default authProvider;