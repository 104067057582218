import Grid from "@material-ui/core/Grid";
import * as React from "react";
import {useResourceContext} from "react-admin";

export const SanitizedP = ({basePath, children, ...props}) => {
    return (
        <p {...props} >
            {children}
        </p>
    );
};

export const SanitizedGrid = ({basePath, children, ...props}) => {
    const resource = useResourceContext();

    return (
        <Grid {...props}>
            {
                React.Children.map(children, child => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, {resource: resource});
                    }
                    return child;
                })
            }
        </Grid>
    );
};