import {ChipField, EmailField, Show, SimpleShowLayout, TextField} from 'react-admin';
import * as React from "react";
import {UserTimelineField} from "./UserTimelineField";

const UserTitle = ({ record }) => (
    <span>Utilizador {record ? `"${record.email}"` : ''}</span>
);

const ResourceShow = props => (
    <Show
        {...props}
        component="div"
        title={<UserTitle/>}
    >
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <EmailField source="email" />
            <TextField source="auth_userid" />
            <ChipField source="instructor_status"/>
            <UserTimelineField />
        </SimpleShowLayout>
    </Show>
);

export default ResourceShow;
