import TaskList from "./list";
import TaskShow from "./show";
import TaskCreate from "./create";
import EventNoteIcon from '@material-ui/icons/EventNote';

const Tasks = {
    name: "tasks",
    list: TaskList,
    create: TaskCreate,
    show: TaskShow,
    icon: EventNoteIcon,
};

export default Tasks;