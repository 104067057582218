import {
    BooleanField,
    Datagrid,
    FilterLiveSearch,
    FunctionField,
    List,
    NullableBooleanInput,
    ReferenceField,
    TextField,
    TextInput
} from 'react-admin';
import * as React from "react";
import BigPagination from "../components/BigPagination";
import IterationFilter from "../components/IterationFilter";
import {DonationsEdit} from "./edition";
import LcDateField from "../components/LcDateField";
import {DateTimeInput} from "../components/date-time-pickers";
import BulkSendReceiptsButton from "./BulkSendReceiptsButton";
import BulkValidateButton from "./BulkValidateButton";
import {getIterationName} from "../iterations/utils";

export const DonationsBulkActionsButtons = (props) => (
    <React.Fragment>
        <BulkSendReceiptsButton {...props} />
        <BulkValidateButton {...props} />
    </React.Fragment>
);

const ResourceList = ({permissions, ...props}) => {
    let filters = [
        <FilterLiveSearch source="q" alwaysOn/>,
        <IterationFilter source="course_iter_id"/>,
        <NullableBooleanInput source="donation_validated"/>,
        <NullableBooleanInput source="invoice_sent"/>,
        <TextInput type="number" source="payment_amount_gt" />,
        <TextInput type="number" source="payment_amount_lt"/>,
        <TextInput type="number" source="payment_amount"/>,
        <DateTimeInput source="updated_at_gte"/>,
        <DateTimeInput source="updated_at_lte"/>,
    ];

    return <List {...props} sort={{field: 'updated_at', order: 'DESC'}}
          filters={filters}
          filterDefaultValues={{'payment_amount_gt': 0}}
          pagination={<BigPagination/>}
          bulkActionButtons={<DonationsBulkActionsButtons />}
    >
        <Datagrid rowClick="edit" expand={<DonationsEdit in_list={true} />}>
            <ReferenceField source="course_iter_id" reference="iterations" link="show">
                <FunctionField render={record => getIterationName(record, true)}/>
            </ReferenceField>
            <TextField source="name"/>
            <TextField fullWidth source="parent_name"/>
            <TextField source="payment_amount" />
            <LcDateField showTime={true} source="updated_at" />
            <BooleanField source="donation_validated" />
            <BooleanField source="invoice_sent" />
        </Datagrid>
    </List>
};



export default ResourceList;
