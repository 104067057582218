import * as React from 'react';
import PropTypes from 'prop-types';
import WifiOffIcon from '@material-ui/icons/WifiOff';

import {
    Button,
    useUpdateMany,
    useNotify,
    useRefresh,
    useUnselectAll,
    CRUD_UPDATE_MANY,
} from 'react-admin';

const noSelection = [];

const BulkSetAbsentButton = ({
    selectedIds = noSelection,
}) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll('session_participants');

    const [approve, { loading }] = useUpdateMany(
        'session_participants',
        selectedIds,
        { 'present': false },
        {
            action: CRUD_UPDATE_MANY,
            onSuccess: () => {
                notify(
                    'resources.sessions.set_absent_success',
                    'info',
                    {},
                );
                refresh();
                unselectAll();
            },
            onFailure: () => {
                notify(
                    'resources.sessions.set_absent_error',
                    'warning'
                );
            },
        }
    );

    return (
        <Button
            label="Marcar Ausente"
            onClick={approve}
            disabled={loading}
        >
            <WifiOffIcon />
        </Button>
    );
};

BulkSetAbsentButton.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BulkSetAbsentButton;
