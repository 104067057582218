import React, { useState, useCallback } from 'react';
import { ReferenceInput, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import DiscordCreateButton from './DiscordCreateButton';
import {discordOption} from "../../utils";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center'
    }
});

const DiscordReferenceInput = props => {
    const classes = useStyles();
    const [version, setVersion] = useState(0);
    const handleChange = useCallback(() => setVersion(version + 1), [version]);

    // TODO add button to post messages

    return (
        <div className={classes.root}>
            <ReferenceInput perPage={1000} key={version} {...props}>
                <SelectInput optionText={discordOption} />
            </ReferenceInput>
            {props.record &&
                <>
                    <DiscordCreateButton onChange={handleChange} record={props.record}/>
                </>
            }
            {/*!!values.forum_category_id && <Button
                component={Link}
                to={{ pathname: "/link to forum" }}
                />*/}
        </div>
    );
};

export default DiscordReferenceInput;
