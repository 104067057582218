import React, { useState, useEffect } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const ApplicationSpecSelectionDialog = ({ open, onClose, onConfirm }) => {
    const [selectedProgramId, setSelectedProgramId] = useState('');
    const [selectedSpecId, setSelectedSpecId] = useState('');
    const [programs, setPrograms] = useState([]);
    const [applicationSpecs, setApplicationSpecs] = useState([]);
    const dataProvider = useDataProvider();
    const translate = useTranslate();

    // Fetch programs on mount
    useEffect(() => {
        dataProvider.getList('programs', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        }).then(({ data }) => {
            setPrograms(data);
        }).catch(error => {
            console.error(error);
        });
    }, [dataProvider]);

    // Fetch application specs when program is selected
    useEffect(() => {
        if (selectedProgramId) {
            dataProvider.getList('application_specs', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'id', order: 'ASC' },
                filter: { 'program_id': selectedProgramId },
            }).then(({ data }) => {
                setSelectedSpecId('');
                setApplicationSpecs(data);
            }).catch(error => {
                console.error(error);
            });
        }
    }, [selectedProgramId, dataProvider]);

    const handleConfirm = () => {
        const selectedSpec = applicationSpecs.find(spec => spec.id === selectedSpecId);
        onConfirm(selectedSpec);
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>{translate('resources.iterations.application_spec_selection_dialog_title')}</DialogTitle>
            <DialogContent>
                <Box mb={2}>
                    <Select
                        value={selectedProgramId}
                        onChange={(e) => setSelectedProgramId(e.target.value)}
                        fullWidth
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            {translate('resources.iterations.fields.course.program.name')}
                        </MenuItem>
                        {programs.map(program => (
                            <MenuItem key={program.id} value={program.id}>
                                {program.name}
                            </MenuItem>
                        ))}
                        </Select>
                </Box>

                <Select
                    value={selectedSpecId}
                    onChange={(e) => setSelectedSpecId(e.target.value)}
                    fullWidth
                    displayEmpty
                    disabled={!selectedProgramId}
                >
                    <MenuItem value="" disabled>
                        {translate('resources.iterations.fields.application_spec_id')}
                    </MenuItem>
                    {applicationSpecs.map(spec => (
                        <MenuItem key={spec.id} value={spec.id}>
                            {spec.edition}
                        </MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {translate('ra.action.cancel')}
                </Button>
                <Button 
                    onClick={handleConfirm} 
                    color="primary" 
                    disabled={!selectedSpecId}
                >
                    {translate('ra.action.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ApplicationSpecSelectionDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default ApplicationSpecSelectionDialog;
