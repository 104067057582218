import {ImageField, ImageInput} from 'react-admin';

export const PreviewImage = ({ record, source, ...props }) => {
    if (typeof (source) == "string") {
        if (!record[source])
            return <span>Sem imagem</span>;
        record = {
            [source]: process.env.REACT_APP_BUCKET_BASE_URL + record[source]
        }
    }
    return <ImageField record={record} source={source} {...props}/>;
}

export const HostedImageInput = props => {
    const formatImage = value => {
        if (!value)
            return {url: null}
        else if (typeof(value) === "string") // Value is null or the url string from the backend, wrap it in an object so the form input can handle it 
            return { url: process.env.REACT_APP_BUCKET_BASE_URL + value };
        else // Else a new image is selected which results in a value object already having a preview link under the url key
            return value;
    }

    return (
        <ImageInput format={ formatImage } accept=".jpg,.jpeg,.png,.bmp" {...props}>
            <ImageField source="url" />
        </ImageInput>
    );
};