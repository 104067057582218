import {
    CheckboxGroupInput, 
    Create, 
    Edit, 
    maxLength, 
    SelectInput, 
    SimpleForm, 
    TextInput, 
    ReferenceInput,
    required
} from 'react-admin';
import ToolbarWithoutDelete from "../components/ToolbarWithoutDelete";
import {months} from "../utils";
import {programOption} from "./utils";

const editionForm = edit => (
    <SimpleForm toolbar={<ToolbarWithoutDelete />}>
        {edit &&
            <TextInput fullWidth disabled source="id" />
        }
        <TextInput fullWidth source="name" validate={required()}/>
        <ReferenceInput fullWidth source="program_id"  reference="programs"  sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText={programOption} validate={required()} />
        </ReferenceInput>
        <TextInput fullWidth source="slug" validate={required()}/>
        <TextInput fullWidth source="short_slug" validate={[required(), maxLength(9)]}/>
        <TextInput fullWidth source="min_staff_members" defaultValue="6" validate={required()}/>
        <SelectInput fullWidth source="looking_for" validate={required()} defaultValue="autores" choices={[
            { id: 'autores', name: 'Autores' },
            { id: 'instrutores', name: 'Instrutores' },
        ]} />
        <CheckboxGroupInput source="possible_months" choices={
            months.map((name, index) => ({id: index + 1, name: name}))
        }
        initialValue={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
        />
    </SimpleForm>
);

const CourseTitle = ({ record }) => {
    return <span>A editar {record ? `"${record.name}"` : ''}</span>;
};

export const CourseEdit = props => (
    <Edit {...props} title={<CourseTitle/>}>
        {editionForm(true)}
    </Edit>
);

export const CourseCreate = props => (
    <Create {...props}>
        {editionForm(false)}
    </Create>
);
