import * as React from 'react';

import {
    Button,
    useUpdate,
    useNotify,
    useRefresh,
    useRecordContext,
} from 'react-admin';
import WifiIcon from "@material-ui/icons/Wifi";

const SetPresentButton = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext()

    const [approve, { loading }] = useUpdate(
        'session_participants',
        record.id,
        { 'present': true },
        null,
        {
            onSuccess: () => {
                notify(
                    'resources.sessions.set_present_success',
                    'info',
                    {},
                );
                refresh();
            },
            onFailure: () => {
                notify(
                    'resources.sessions.set_present_error',
                    'warning'
                );
            },
        }
    );

    return (
        <Button
            label="Marcar Presente"
            onClick={approve}
            disabled={loading}
        >
            <WifiIcon />
        </Button>
    );
};

export default SetPresentButton;
