import * as React from 'react';
import {useState} from 'react';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import {Button, useDataProvider, useNotify, useRecordContext,} from 'react-admin';

const SendReceiptButton = () => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const record = useRecordContext();

    const submit = async () => {
        setLoading(true);
        dataProvider.create(`donations/send_receipts/`, {
            data: {
                raw_request: "POST",
                participants: [{
                    course_iter_id: record.course_iter_id,
                    user_id: record.user_id
                }]
            }
        }).then(() => {
            notify("resources.donations.send_receipts_success");
        }).catch(error =>
            notify(error.message, 'error')
        ).finally(() =>
            setLoading(false)
        )
    }

    return (
        <Button
            label="resources.donations.send_receipts_button"
            onClick={submit}
            disabled={loading}
        >
            <PictureAsPdfIcon />
        </Button>
    );
};

export default SendReceiptButton;
