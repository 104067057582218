import * as React from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import {Button, useDataProvider, useNotify, useUnselectAll,} from 'react-admin';

const noSelection = [];

const BulkSendReceiptsButton = ({
    selectedIds = noSelection,
}) => {
    const notify = useNotify();
    const unselectAll = useUnselectAll('donations');
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();

    const submit = async () => {
        setLoading(true);
        dataProvider.create(`donations/send_receipts/`, {
            data: {
                raw_request: "POST",
                participants: selectedIds.map(id => {
                    const ids = id.split('/');
                    return {
                        course_iter_id: ids[0],
                        user_id: ids[1]
                    }
                })
            }
        }).then(() => {
            notify("resources.donations.send_receipts_success");
            unselectAll();
        }).catch(error =>
            notify(error.message, 'error')
        ).finally(() =>
            setLoading(false)
        )
    }

    return (
        <Button
            label="resources.donations.send_receipts_button"
            onClick={submit}
            disabled={loading}
        >
            <PictureAsPdfIcon />
        </Button>
    );
};

BulkSendReceiptsButton.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BulkSendReceiptsButton;
