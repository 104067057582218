import * as React from "react";
import {Button, useNotify, useRecordContext, useRefresh} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MUIButton from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider } from 'react-admin';
import DraftsIcon from "@material-ui/icons/Drafts";
import MUIDateTimePicker from "../../components/date-time-pickers/MUIDateTimePicker";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

const SendSelectionEmailsButton = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();

    const [open, setOpen] = React.useState(false);
    const [scheduleForm, setScheduleForm] = React.useState(false);
    const [eta, setEta] = React.useState(new Date());

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        dataProvider.create(`course_management/send_selection_emails/${record.id}`, {
            data: {
                raw_request: "POST",
                schedule_form: scheduleForm,
                eta: eta
            }
        }).then(() => {
            notify("resources.iterations.sent_selection_emails");
            refresh();
        }).catch(error =>
            notify(error.message, 'error')
        ).finally(() =>
            setOpen(false)
        );
    };

    return (
        <div className={classes.root}>
            <Button
                label="resources.iterations.send_selection_emails_button"
                onClick={handleClickOpen}
            >
                <DraftsIcon />
            </Button>
            <Dialog open={open} onClose={handleClose} disableEnforceFocus={true} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Enviar emails de seleção</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            scheduleForm ?
                                <>As candidaturas rejeitadas irão receber um email indicando que não foram selecionadas, enquanto que as candidaturas selecionadas irão receber um email indicando que <strong>na data selecionada abaixo</strong> será enviado o formulário de confirmação (que será depois enviado automaticamente). NOTA: As tarefas de follow up começam a contar a partir da data de envio do formulário de confirmação.</>
                                :
                                <>As candidaturas rejeitadas irão receber um email indicando que não foram selecionadas, enquanto que as candidaturas selecionadas irão receber um email com o formulário de confirmação.</>
                        }
                    </DialogContentText>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={scheduleForm}
                                onChange={(event) => setScheduleForm(event.target.checked)}
                                name="schedule_form" />
                        }
                        label="Agendar envio do formulário de confirmação"
                    />
                    {scheduleForm &&
                        <MUIDateTimePicker
                            label="Data de envio"
                            value={eta} onChange={ setEta }
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <MUIButton onClick={handleClose} color="primary">
                        Cancelar
                    </MUIButton>
                    <MUIButton onClick={handleSubmit} color="primary">
                        Enviar
                    </MUIButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SendSelectionEmailsButton;