import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Auth0Provider} from "@auth0/auth0-react";

ReactDOM.render(
  <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirectUri={process.env.REACT_APP_AUTH0_CALLBACK_URL}
      useRefreshTokens={true}
      cacheLocation="localstorage"
  >
      <App />
  </Auth0Provider>,
  document.getElementById('root')
);
