import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import StarsIcon from '@material-ui/icons/Stars';
import AuthorIcon from '@material-ui/icons/Create';
import InstructorIcon from '@material-ui/icons/School';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {linkToRecord, Loading, useGetMany, useGetManyReference, useRecordContext} from "react-admin";
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from 'react-router-dom';
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import AssignmentIcon from '@material-ui/icons/Assignment';
import DateFnsAdapter from "@date-io/date-fns";
import defaultLocale from "date-fns/locale/pt";
import { getIterationName } from '../iterations/utils';

const useStyles = makeStyles(() => ({
    paper: {
        padding: '6px 16px'
    },
    paper_in_list: {
        padding: '6px 16px',
        backgroundColor: '#303030'
    }
}));

const dateFns = new DateFnsAdapter({locale: defaultLocale});

const GetData = ref => {
    const { data: applications, ids: applicationIds, loading: loadingApplications, error: errorApplications,
        loaded: loadedParticipants } = useGetManyReference(
        'applications',
        'user_id',
        ref,
        { page: 1, perPage: 1000 },
        { field: 'created_at', order: 'DESC' },
        {},
        'users',
    );

    const { data: staffs, ids: staffIds, loading: loadingStaff, error: errorStaff,
        loaded: loadedStaffs } = useGetManyReference(
        'staff_users',
        'user_id',
        ref,
        { page: 1, perPage: 1000 },
        { field: 'created_at', order: 'DESC' },
        {},
        'users',
    );

    const { data: iterations, loading: loadingIterations, error: errorIterations, loaded: loadedIterations } = useGetMany(
        'iterations',
        [
            ...(new Set([...applicationIds.map(id => applications[id].course_iter_id),
                ...staffIds.map(id => staffs[id].course_iter_id)]))
        ],
        { enabled: loadedParticipants && loadedStaffs }
    );

    return {
        loading: loadingApplications || loadingStaff || loadingIterations,
        error: errorApplications || errorStaff || errorIterations,
        loadedIterations,
        iterations,
        staffs,
        applications
    }
}

const GetUserHistory = user_id => {
    const {loading, error, loadedIterations, iterations, staffs, applications} = GetData(user_id);

    const exported_iterations = iterations.sort((a, b) =>
        new Date(b.start_date) - new Date(a.start_date)
    ).map(iteration => {
        if (!iteration) return null;

        const id = `${iteration.id}/${user_id}`;
        const staff = staffs[id];
        const application = applications[id];

        return {
            id: iteration.id,
            date: dateFns.formatByString(new Date(iteration.start_date), "MMMM 'de' yyyy"),
            name: getIterationName(iteration, true),
            staff: staff,
            application: application
        }
    });

    return {
        loading,
        error,
        loadedIterations,
        iterations: exported_iterations,
    }
}

export const UserTimelineField = (props) => {
    const record = useRecordContext(props);
    const classes = useStyles();

    const {loading, error, iterations, loadedIterations} = GetUserHistory(record.id);

    if (loading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    const IterationTitleComponent = ({iteration}) => {
        const linkToIteration = linkToRecord('/iterations', iteration.id, 'show');

        return (
            <Link component={RouterLink} to={linkToIteration}>
                <Typography variant="h6" component="h2" color={iteration.staff ? "secondary" : "primary"}>
                    {iteration.name}
                </Typography>
            </Link>
        );
    };

    const IterationTimelineDot = ({iteration}) => {
        const staff = iteration.staff;

        if (staff){
            if (staff.roles.includes("coordenador"))
                return (
                    <TimelineDot color="secondary" variant="outlined">
                        <StarsIcon />
                    </TimelineDot>
                )
            else if (staff.roles.includes("instrutor"))
                return (
                    <TimelineDot color="secondary">
                        <InstructorIcon />
                    </TimelineDot>
                )
            else if (staff.roles.includes("autor"))
                return (
                    <TimelineDot color="secondary">
                        <AuthorIcon />
                    </TimelineDot>
                )
            return null;
        }

        const application = iteration.application;
        if (application){
            if (application.accepted)
                return (
                    <TimelineDot color="primary" variant="outlined">
                        <LaptopMacIcon />
                    </TimelineDot>
                )
            else
                return (
                    <TimelineDot color="primary">
                        <AssignmentIcon />
                    </TimelineDot>
                )
        }

        return null;
    };

    const IterationContentComponent = ({iteration}) => {
        const arrayToText = (array) => {
            if (array.length <= 2) {
                return array.join(' e ');
            } else {
                return array.slice(0, -1).join(', ') + ' e ' + array[array.length - 1];
            }
        }

        const staff = iteration.staff;

        if (staff){
            const linkToStaff = linkToRecord('/staff_users', staff.id, 'edit');

            const roles = ["Coordenador", "Instrutor", "Autor"].filter(role => staff.roles.includes(role.toLowerCase()));
            return (
                <Link component={RouterLink} to={linkToStaff}>
                    <Typography component="h3" color="textSecondary">
                        {arrayToText(roles)}
                    </Typography>
                </Link>
            );
        }

        const application = iteration.application;
        if (application){
            const linkToApplication = linkToRecord('/applications', application.id, 'show');
            return (
                <Link component={RouterLink} to={linkToApplication}>
                    <Typography component="h3" color="textSecondary">
                        {application.accepted ? "Aluno" : "Candidato"}
                    </Typography>
                </Link>
            );
        }

        return null;
    };

    return (
        <Timeline align="alternate">
            {loadedIterations &&
                iterations.map((iteration, index) => {
                    return (
                        <TimelineItem key={iteration.id}>
                            <TimelineOppositeContent>
                                <Typography variant="body2" color="textPrimary">
                                    {iteration.date}
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <IterationTimelineDot iteration={iteration} />
                                {index < iterations.length - 1 ? <TimelineConnector /> : null}
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={props.in_list ? classes.paper_in_list : classes.paper}>
                                    <IterationTitleComponent iteration={iteration} />
                                    <IterationContentComponent iteration={iteration} />
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    )
                })
            }
        </Timeline>
    );
}

UserTimelineField.defaultProps = {
    addLabel: true,
    fullWidth: true,
    label: "Atividades TT2",
    in_list: false
}