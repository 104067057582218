import {DateField, useLocale} from "react-admin";
import {timezone} from "../App";

const LcDateField = props => {
    const locale = useLocale();

    const options = props.options || {};
    options['timeZone'] = timezone;

    return <DateField locales={locale} {...props} options={options} />;
}

LcDateField.defaultProps = {
    addLabel: true
}

export default LcDateField;