import { TextInput, required } from 'react-admin';
import { useState, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { slugify } from '../utils';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const EditionAndSlugInput = ({resource}) => {
    const form = useForm();
    const formData = useFormState();
    const [generateSlug, setGenerateSlug] = useState(
        !formData.values.slug || formData.values.slug === slugify(formData.values.edition)
    );

    useEffect(() => {
        form.change('slug', generateSlug ? slugify(formData.values.edition) : formData.values.slug);
    }, [generateSlug, form, formData.values.edition, formData.values.slug]);

    return (
        <Grid container spacing={1} style={{width: "100%"}}>
            <Grid item xs={8}>
                <TextInput 
                    fullWidth 
                    source="edition"
                    resource={resource}
                    validate={required()} 
                />
            </Grid>
            <Grid item xs={4}>
                <div style={{ position: 'relative' }}>
                    <TextInput
                        fullWidth
                        source="slug"
                        resource={resource}
                        validate={required()}
                        onChange={() => setGenerateSlug(false)}
                        onBlur={(e) => {
                            if (!generateSlug && !e.target.value) {
                                form.change('slug', formData.initialValues.slug);
                            }
                        }}
                    />
                    <FormControlLabel
                        style={{position: 'absolute', right: '0', transform: 'translateY(25%)'}}
                        control={<Checkbox checked={generateSlug} onChange={(e) => setGenerateSlug(e.target.checked)} />}
                        label="Auto"
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default EditionAndSlugInput;
