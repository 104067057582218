import * as React from "react";
import {Button, useNotify, useRecordContext, useRefresh} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MUIButton from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider } from 'react-admin';
import DraftsIcon from "@material-ui/icons/Drafts";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

const SendRejectionEmailsButton = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        dataProvider.create(`course_management/send_program_rejection_emails/${record.id}`, {
            data: { raw_request: "POST" }
        }).then(() => {
            notify("resources.application_specs.sent_rejection_emails");
        }).catch(error => {
            console.log(error);
            notify(error.message, 'error')
        }).finally(() => {
            setOpen(false)
            refresh();
        });
    };

    return (
        <div className={classes.root}>
            <Button
                label="resources.application_specs.send_rejection_emails_button"
                onClick={handleClickOpen}
            >
                <DraftsIcon />
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Enviar emails de rejeição</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        As candidaturas rejeitadas irão receber um email indicando que não foram selecionadas.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MUIButton onClick={handleClose} color="primary">
                        Cancelar
                    </MUIButton>
                    <MUIButton onClick={handleSubmit} color="primary">
                        Enviar
                    </MUIButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SendRejectionEmailsButton;