import React from 'react';
import { useInput } from 'react-admin';
import { JsonEditor as Editor} from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/tomorrow_night_eighties';
import Ajv from 'ajv';


const ajv = new Ajv({ allErrors: true, verbose: true });

const removeNulls = (obj) => {
    try {
        return JSON.parse(JSON.stringify(obj), (_, value) => 
            value === null ? undefined : value
        );
    } catch {
        return obj;
    }
};

export const JSONInput = props => {
    const {
        input: { value, onChange },
    } = useInput({...props, parse: removeNulls, format: removeNulls});

    const style = {
        height: '500px',
        ...(props.disabled ? {
            opacity: 0.1,
            pointerEvents: 'none'
        } : {})
    };

    return (
        <>
            <Editor
                value={value || props.default || []}
                onChange={onChange}
                mode="code"
                theme="ace/theme/tomorrow_night_eighties"
                ace={ace}
                allowedModes={["tree", "form", "code"]}
                htmlElementProps={{ style }}
                schema={props.schema}
                ajv={ajv}
            />
            <br />
        </>
    );
};


JSONInput.defaultProps = {
    addLabel: true,
    fullWidth: true
}
