import * as React from 'react';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import {
    Button,
    useNotify,
    useDataProvider, useRecordContext,
} from 'react-admin';
import {useState} from "react";

const SendCertificatesButton = () => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const record = useRecordContext();

    const submit = async () => {
        setLoading(true);
        dataProvider.create(`course_management/send_certificates/`, {
            data: {
                raw_request: "POST",
                participants: [{
                    course_iter_id: record.course_iter_id,
                    user_id: record.user_id
                }]
            }
        }).then(() => {
            notify("resources.participants.send_certificates_success");
        }).catch(error =>
            notify(error.message, 'error')
        ).finally(() =>
            setLoading(false)
        )
    }

    return (
        <Button
            label="resources.participants.send_certificates_button"
            onClick={submit}
            disabled={loading}
        >
            <PictureAsPdfIcon />
        </Button>
    );
};

export default SendCertificatesButton;
