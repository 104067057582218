export const taskNames = ['course_management.add_staff_members',
    'course_management.send_confirmation_form_emails',
    'course_management.send_selection_emails',
    'course_management.send_program_rejection_emails',
    'follow_up.follow_up_confirmation_1',
    'follow_up.follow_up_confirmation_2',
    'follow_up.follow_up_contacts_to_call',
    'follow_up.follow_up_discord',
    'application_submissions.send_application_submission_received',
    'send_aluno_logins.send_aluno_logins',
    'send_aluno_logins.send_application_received',
    'send_course_certificates.bulk_send',
    'invoice.send_invoices'
]

export const taskChoices = taskNames.map(item => ({name: item, id: "web.tasks." + item}));