import {downloadCSV} from "react-admin";
import jsonExport from 'jsonexport/dist';
import DateFnsAdapter from "@date-io/date-fns";
import defaultLocale from "date-fns/locale/pt";
import {chunk, isPlainObject} from 'lodash';

const dateFns = new DateFnsAdapter({locale: defaultLocale});

// JSON fields 'questions' and 'confirmation_data' are split into multiple columns
// 'course_name' and 'application_spec_name' are computed fields
const FIELDS_TO_EXPORT = [
    'created_at',
    // for applications (course_name is computed)
    'course_iter_id', 'course_name',
    // for application_submissions (application_spec_name is computed)
    'application_spec_id', 'application_spec_name',
    // user details/personal data
    'user_id', 'name', 'user.email',
    'phone', 'parent_name', 'parent_email', 'parent_phone', 'birthdate', 'gender',
    'nationality', 'school_year', 'school_name', 'school_group', 'school_location',
    'school_district',
    // history
    'student_history', 'staff_history', 'history_t2', 'history_t2_app', 'history_oc',
    // info
    'info_interests', 'info_english', 'info_computer', 'info_internet',
    // application questions
    'questions',
    // confirmation data (only for applications)
    'confirmed', 'confirmation_data',
];


// removes duplicates and requests data in chunks
const getRequestPromises = async (dataProvider, list, resource, maxPerReq = 45) => {
    const uniqueList = [...new Set(list)];
    const chunks = chunk(uniqueList, maxPerReq);

    const allRes = await Promise.all(chunks.map(group =>
        dataProvider.getManyReference(
            resource,
            {
                target: 'user_id',
                id: group,
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'created_at', order: 'DESC' },
            }
        )
    ));

    return allRes.reduce((acc, curr) => acc.concat(curr.data), []);
};

// gets submissions (without associated application) plus all applications, by user_id
const getApplicationHistory = async (userIds, dataProvider) => {
    const history = intoUserDict([
        ...(await getRequestPromises(dataProvider, userIds, 'applications')),
        ...(await getRequestPromises(dataProvider, userIds, 'application_submissions'))
    ]);

    // filter out submissions that have an associated application, and sort by creation date
    for (const [userId, userApplications] of Object.entries(history)) {
        history[userId] = userApplications
            .filter((app, _, arr) => !app.application_spec_id || !arr.some(a => a.course_iteration?.application_spec_id === app.application_spec_id))
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }
  
    return history;
};

const getStaffHistory = async (userIds, dataProvider) => {
    return intoUserDict(await getRequestPromises(dataProvider, userIds, 'staff_users'));
};

const historyString = (item, type) => {
    let res = item.course_iteration?.course.name || item.application_spec.program.name;
    if (item.created_at) {
        res += ` [${dateFns.formatByString(new Date(item.created_at), "MMMM 'de' yyyy")}]`;
    }

    if (type === 'application') {
        let status = '(candidatou-se)';
        if ('accepted' in item) {
            if (item.accepted) status = '(foi aluno)'
            if (item.comment) status += ` Comentário: ${item.comment}`;
            if (item.recommendation && item.recommendation !== 'neutro') status += ` Recomendação: ${item.recommendation}`;
        }
        return `${res} ${status}`;
    }
    else if (type === 'staff') {
        return `${res} (${item.roles.join(", ")})`;
    }
    return res;
}

const intoUserDict = data => {
    let userDict = {};
    data.forEach(x => {
        if (!(x.user_id in userDict))
            userDict[x.user_id] = [];
        userDict[x.user_id].push(x);
    });
    return userDict;
};

const fieldToString = (obj) => {
    if (obj === null || obj === undefined) return '';
    if (Array.isArray(obj)) return obj.map(fieldToString).join('\n');
    return String(obj);
};

const getValue = (obj, field, resource) => {
    // computed fields
    if (resource === 'applications') {
        if (field === 'course_name') return obj.course_iteration?.course.name;
    }
    else if (resource === 'application_submissions') {
        if (field === 'application_spec_name') return obj.application_spec.program.name + ' ' + obj.application_spec.edition;
    }
    // inner fields
    return field.split('.').reduce((o, key) => o?.[key], obj);
}


export const applicationExporter = async (applications, _, dataProvider, resource='applications') => {
    const headers = new Set();
    const userIds = applications.map(a => a.user_id);

    const applicationHistory = await getApplicationHistory(userIds, dataProvider);
    const staffHistory = await getStaffHistory(userIds, dataProvider);

    const toExport = applications.map(application => {
        const userApplicationHistory = applicationHistory[application.user_id] ?? [];
        application['student_history'] = userApplicationHistory.map(x => historyString(x, 'application')).join("\n");

        const userStaffHistory = staffHistory[application.user_id] ?? [];
        application['staff_history'] = userStaffHistory.map(x => historyString(x, 'staff')).join("\n");

        return FIELDS_TO_EXPORT.reduce((acc, field) => {
            const val = getValue(application, field, resource);

            if (isPlainObject(val)) {
                // split JSON fields into separate columns
                Object.entries(val).forEach(([k, v]) => {
                    acc[`${field}.${k}`] = fieldToString(v);
                    headers.add(`${field}.${k}`);
                });
            }
            else if(val !== undefined) {
                acc[field] = fieldToString(val);
                headers.add(field);
            }
            return acc;
        }, {});
    });

    jsonExport(toExport, { headers: Array.from(headers) }, (err, csv) => {
        downloadCSV(csv, resource);
    });
};
