import EmailIcon from '@material-ui/icons/Email';
import SentEmailsList from "./list";
import SentEmailsShow from "./show";

const SentEmails = {
    name: "sent_emails",
    list: SentEmailsList,
    show: SentEmailsShow,
    icon: EmailIcon,
};

export default SentEmails;