import {
    Create, SimpleForm, TextInput, required, SelectInput
} from 'react-admin';
import * as React from "react";
import ToolbarWithoutDelete from "../components/ToolbarWithoutDelete";
import {taskChoices} from "./utils";
import {SanitizedP} from "../components/SanitizedElements";

const ResourceCreate = props => (
    <Create
        {...props}
    >
        <SimpleForm toolbar={<ToolbarWithoutDelete />}>
            <SanitizedP fullWidth>
                Esta funcionalidade é considerada avançada e deverá apenas ser utilizada em casos muito específicos.
            </SanitizedP>
            <SelectInput source="name" choices={taskChoices} validate={required()}/>
            <TextInput source="args"/>
            <TextInput source="kwargs"/>
        </SimpleForm>
    </Create>
);

export default ResourceCreate;