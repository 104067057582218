import * as React from 'react';
import { Link } from '@material-ui/core';
import { useRecordContext } from 'react-admin';

const PrefixedUrlField = ({ source, prefix, target}) => {
    const record = useRecordContext();
    const value = record && record[source];

    if (value == null) {
        return null;
    }

    return (
        <Link href={`${prefix}${value}`} target={target} rel="noopener noreferrer">
            {value}
        </Link>
    );
};

PrefixedUrlField.defaultProps = {
    addLabel: true,
    target: '_blank'
};

export default PrefixedUrlField;