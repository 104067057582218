const theme = {
    axis: {
        fontSize: "16px",
        tickColor: "#eee",
        ticks: {
            line: {
                stroke: "#555555"
            },
            text: {
                fill: "#ffffff"
            }
        },
        legend: {
            text: {
                fill: "#aaaaaa"
            }
        },
    },
    tooltip: {
        container: {
            background: '#424242',
        },
    },
};

export const commonChartOptions = {
    theme: theme,
    borderRadius: "5px",
    padding: 0.3,
    innerPadding: 2,
    margin: {
        "top": 20,
            "right": 0,
            "bottom": 50,
            "left": 40
    },
    labelTextColor: "#fff",
    valueScale: {
        type: 'linear'
    },
    indexScale: {type: 'band', round: true},
    enableGridX: false,
    enableGridY: true,
    enableLabel: true,
    axisTop: null,
    axisBottom: {
        tickSize: 0,
        tickPadding: 10
    },
    axisRight: null,
    axisLeft: {
        tickValues: 5,
    },
    labelSkipHeight: 5
};
