import React from 'react';
import { useFormState, useForm } from 'react-final-form';
import { FormControl, FormControlLabel, Switch, FormHelperText } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const ApplicationScopeInput = () => {
    const form = useForm();
    const formData = useFormState();
    const translate = useTranslate();

    return (
        <FormControl>
            <FormControlLabel
                label={translate('resources.application_specs.iteration_scoped')}
                control={
                    <Switch
                        checked={formData.values.application_scope === 'iteration'}
                        onChange={(e) => form.change('application_scope', e.target.checked ? 'iteration' : 'application_spec')}
                    />
                }
            />  
            <FormHelperText>
                {translate('resources.application_specs.helpers.iteration_scoped')}
            </FormHelperText>
        </FormControl>
    );
};

export default ApplicationScopeInput;
