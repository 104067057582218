import {
    BooleanField,
    BooleanInput,
    EmailField,
    FunctionField,
    ReferenceField,
    SaveButton,
    SaveContextProvider,
    SelectInput,
    Show,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
    Loading,
    Labeled,
    useNotify,
    useRecordContext,
    useUpdate,
    useGetOne,
    useDataProvider
    
} from 'react-admin';
import {useCallback, useMemo, useState, useEffect} from "react";
import {TextArrayField} from "../components/TextArrayField";
import {FormResponseField} from "../components/FormResponseField";
import UserPageButton from "../components/UserPageButton";
import {UserTimelineField} from "../users/UserTimelineField";
import LcDateField from "../components/LcDateField";
import SendLoginsButton from "./SendLoginsButton";
import { getIterationName } from '../iterations/utils';


const ApplicationTitle = ({ record }) => (
    <span>Candidatura {record ? `"${record.name} [${getIterationName(record.course_iteration, true)}]"` : ''}</span>
);

const ApplicationShowActions = ({ data }) => (
    <TopToolbar>
        {data &&
            <UserPageButton {...data}/>
        }
        <SendLoginsButton />
    </TopToolbar>
);

const EditAside = () => {
    const record = useRecordContext();
    const notify = useNotify();

    const [update] = useUpdate('applications');

    const handleSave = useCallback(
            (values) => {
                update(
                    'applications',
                    values.id,
                    values,
                    {},
                    {
                        onSuccess: () => {
                            notify(
                                'ra.notification.updated',
                                'info',
                                {
                                    smart_count: 1,
                                }
                            );
                        },
                        onFailure: error => {
                            notify(
                                typeof error === 'string'
                                    ? error
                                    : error.message ||
                                    'ra.notification.http_error',
                                'warning',
                                {
                                    _:
                                        typeof error === 'string'
                                            ? error
                                            : error && error.message
                                            ? error.message
                                            : undefined,
                                }
                            );
                        },
                    }
                )
        },
        [update, notify]);

    const saveContext = useMemo(() => ({
        save: handleSave
    }), [handleSave]);

    const ApplicationSideToolbar = props => (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    );

    return record ? (
        <SaveContextProvider value={saveContext}>
            <SimpleForm resource="applications" record={record} save={handleSave} toolbar={<ApplicationSideToolbar />}>
                <BooleanInput source="accepted"/>
                <TextInput multiline source="comment"/>
                <SelectInput
                    source="recommendation"
                    defaultValue="neutro"
                    choices={[
                        {id: "não", name: "Não recomendado (-)"},
                        {id: "neutro", name: "Neutro (~)"},
                        {id: "sim", name: "Recomendado (+)"}
                    ]}
                />
            </SimpleForm>
        </SaveContextProvider>
    ) : null;
};

const ApplicationQuestions = ({ record }) => {
    const dataProvider = useDataProvider();    
    const [questions, setQuestions] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getApplicationSpec = () => dataProvider.getOne('application_specs', { id: record.course_iteration.application_spec_id });
        const getIteration = () => dataProvider.getOne('iterations', { id: record.course_iter_id });
        
        getApplicationSpec().then(({data: applicationSpec}) => {
            const isIterationScoped = applicationSpec.application_scope === 'iteration';
            return isIterationScoped ? 
                getIteration().then(({data: iteration}) => iteration?.application_questions) : 
                applicationSpec.questions;
        })
        .then(setQuestions)
        .catch(setError);
    }, [dataProvider, record]);

    if(error) { return <Labeled label="ra.page.error" />; }
    if(!questions) { return <Loading />; }
    return <FormResponseField source="questions" questions={questions} />;
}

const ConfirmationQuestions = ({ record }) => {
    const { data, loading, error } = useGetOne('application_specs', record.course_iteration.application_spec_id);
    if(loading) { return <Loading />; }
    if(error) { return <Labeled label="ra.page.error" />; }
    const questions = data?.confirmation_format.questions;

    return (
        <>
            <Labeled label="resources.applications.fields.confirmed">
                <BooleanField source="confirmed" />
            </Labeled>
            {record.confirmed &&
                <FormResponseField
                    source="confirmation_data"
                    questions={questions}
                    renderTitles={false}
                />
            }
        </>
    );
};


const ResourceShow = props => (
    <Show
        aside={<EditAside />}
        {...props}
        component="div"
        title={<ApplicationTitle/>}
        actions={props.in_list ? null : <ApplicationShowActions />}
    >
        <TabbedShowLayout syncWithLocation={!props.in_list}>
            <Tab label="Contactos">
                <LcDateField source="created_at" showTime={true}/>
                <ReferenceField source="course_iter_id" reference="iterations" link="show">
                    <FunctionField render={record => getIterationName(record, true)} />
                </ReferenceField>
                <TextField fullWidth source="name" />
                <ReferenceField link={false} fullWidth label="Email" source="user_id" reference="users">
                    <EmailField fullWidth source="email" />
                </ReferenceField>
                <TextField fullWidth source="phone" />
                <TextField source="parent_name" />
                <EmailField source="parent_email" />
                <TextField source="parent_phone" />
            </Tab>
            <Tab label="Dados Pessoais">
                <LcDateField source="birthdate" />
                <TextField source="gender" />
                <TextField source="nationality" />
                <TextField source="school_year" />
                <TextField source="school_name" />
                <TextField source="school_group" />
                <TextField source="school_location" />
                <TextField source="school_district" />
            </Tab>
            <Tab label="Histórico e Info">
                <TextArrayField source="history_t2" />
                <TextField source="history_t2_app" />
                <TextField source="history_oc" />
                <TextArrayField source="info_interests" />
                <TextField source="info_english" />
                <TextField source="info_computer" />
                <TextField source="info_internet"/>
            </Tab>
            <Tab label="Perguntas">
                <ApplicationQuestions />
            </Tab>
            <Tab label="Histórico">
                <ReferenceField link={false} fullWidth label="Atividades TT2" source="user_id" reference="users">
                    <UserTimelineField fullWidth in_list={props.in_list}/>
                </ReferenceField>
            </Tab>
            <Tab label="Confirmação">
                <ConfirmationQuestions />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default ResourceShow;