import {Edit, SelectInput, SimpleForm, TextInput} from 'react-admin';
import * as React from "react";
import ToolbarWithoutDelete from "../components/ToolbarWithoutDelete";

const UserTitle = ({ record }) => (
    <span>Utilizador {record ? `"${record.email}"` : ''}</span>
);

const UsersEdit = props => (
    <Edit
        {...props}
        component="div"
        title={<UserTitle/>}
    >
        <SimpleForm toolbar={<ToolbarWithoutDelete />}>
            <TextInput fullWidth disabled source="id" />
            <TextInput fullWidth disabled source="name" />
            <TextInput fullWidth disabled source="email" />
            <TextInput fullWidth disabled source="auth_userid" />
            <SelectInput choices={["habilitado", "não habilitado", "blacklisted"].map(item => ({name: item, id: item}))} fullWidth source="instructor_status"/>
        </SimpleForm>
    </Edit>
);

export default UsersEdit;
