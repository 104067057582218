export const permissions = {
    staff_users: {
        list: ["editor"],
        edit: ["editor"],
        show: ["coordinator"],
    },
    iterations: {
        list: ["coordinator"],
        show: ["coordinator"],
    },
    users: {
        show: ["coordinator"],
    },
    courses: {
        list: ["coordinator"],
        show: ["coordinator"],
    },
    participants: {
        list: ["coordinator"],
        show: ["coordinator"],
    },
    sessions: {
        list: ["coordinator"],
        show: ["coordinator"],
    },
    session_participants: {
        list: ["coordinator"],
    },
}