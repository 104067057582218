import {
    List,
    SimpleList, 
    Datagrid, 
    TextField, 
    FunctionField, 
    EditButton, 
} from 'react-admin';
import {useMediaQuery, Typography} from '@material-ui/core';
import BigPagination from "../components/BigPagination";
import { getApplicationSpecName } from './utils';
import LcDateField from "../components/LcDateField";
import CloneButtonFetched from "../components/CloneButtonFetched";


const ResourceList = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List {...props} 
            bulkActionButtons={false} 
            sort={{ field: 'start_date', order: 'DESC' }} 
            pagination={<BigPagination />}
            exporter={false}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => <Typography variant="body2">{getApplicationSpecName(record)}</Typography>}
                    secondaryText={record => <>
                        <LcDateField source="start_date" record={record} />
                        {' - '} 
                        <LcDateField source="end_date" record={record} />
                    </>}
                    linkType="show"
                />
            ) : (
                <Datagrid rowClick="show">
                    <FunctionField 
                        label="resources.application_specs.full_name"
                        render={record => getApplicationSpecName(record)}
                    />
                    <TextField source="program.slug" sortBy="program/slug" />
                    <TextField source="edition" />
                    <LcDateField source="start_date" />
                    <LcDateField source="end_date" />
                    <EditButton/>
                    <CloneButtonFetched />
                </Datagrid>
            )}
        </List>
    );
};

export default ResourceList;