import { 
    List, 
    Datagrid,
    TextField, 
    FunctionField, 
    BooleanField,
    ReferenceField,
    ReferenceInput, 
    SelectInput,
    NullableBooleanInput, 
    TextInput,
} from 'react-admin';
import BigPagination from "../components/BigPagination";
import BulkRejectButton from "./BulkRejectButton";
import BulkAssignIterationsButton from "./BulkAssignIterationsButton";
import ApplicationSubmissionsShow from "./show";
import {makeStyles} from '@material-ui/core/styles';
import {getApplicationSpecName} from '../application_specs/utils';
import LcDateField from "../components/LcDateField";
import AssignedIterationsField from './AssignedIterationsField';
import RejectInput from './RejectInput';
import {applicationExporter} from '../applications/ApplicationExporter';

const useStyles = makeStyles(() => ({
    thirdColumn: {
        '& .MuiTableCell-root:nth-child(4)': {
            minWidth: '20%',
            maxWidth: '400px', // needed to allow ellipsize on long iteration names
        },
    },
}));

export const BulkActionButtons = (props) => (
    <>
        <BulkAssignIterationsButton {...props} />
        <BulkRejectButton {...props} />
    </>
);

export const submissionFilters = [
    <ReferenceInput 
        perPage={1000} 
        source="application_spec_id" 
        reference="application_specs"
    >
        <SelectInput source="id" optionText={((record)=> getApplicationSpecName(record))} />
    </ReferenceInput>,
    <SelectInput
        source="school_district"
        choices={["Açores", "Aveiro", "Beja", "Braga", "Bragança", "Castelo Branco", "Coimbra", "Évora", "Faro",
            "Guarda", "Leiria", "Lisboa", "Madeira", "Portalegre", "Porto", "Santarém", "Setúbal", "Viana do Castelo",
            "Vila Real", "Viseu", "Outro"].map(d => ({id: d, name: d}))}
    />,
    <SelectInput
        source="school_year"
        choices={["5º ano", "6º ano", "7º ano", "8º ano", "9º ano", "10º ano", "11º ano", "12º ano", "Outro"].map(d => ({id: d, name: d}))}
    />,
    <TextInput source="user_id" parse={v => v.split(/\s+/)}/>,
    <NullableBooleanInput source="rejected" />
];

export const ApplicationSubmissionsDatagrid = (props) => {
    const classes = useStyles();

    return <Datagrid 
        classes={{ table: classes.thirdColumn }} rowClick={()=>false} expand={<ApplicationSubmissionsShow in_list="true" />} {...props} 
    >
        {!props.in_show && <ReferenceField source="application_spec_id" reference="application_specs" link="show">
            <FunctionField render={record => getApplicationSpecName(record)} />
        </ReferenceField>}
        <AssignedIterationsField 
            showAssign={true}
            label="resources.application_submissions.assigned_iterations" 
        />
        <TextField source="name" />
        <TextField source="school_district"/>
        <TextField source="school_name"/>
        <TextField source="school_year"/>
        <LcDateField source="created_at"/>
        <RejectInput source="rejected" />
        <BooleanField source="rejection_email_sent" />
    </Datagrid>
}

const ResourceList = (props) => {
    const exporter = (submissions, _, dataProvider) => 
        applicationExporter(submissions, null, dataProvider, props.resource);

    return (
        <List {...props}
            bulkActionButtons={<BulkActionButtons />} 
            sort={{ field: 'created_at', order: 'DESC' }}
            exporter={exporter}
            filters={submissionFilters}
            pagination={<BigPagination />}
        >
            <ApplicationSubmissionsDatagrid />
        </List>
    );
};

export default ResourceList;