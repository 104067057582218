import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        border: "none",
        width: "1000px",
        height: "500px"
    }
}));

export const SentEmailField = ({ record, source }) => {
    const classes = useStyles();

    return (
        record && record[source] ?
            (<iframe
                className={classes.root}
                title={source}
                sandbox="allow-same-origin"
                srcDoc={record[source]}
            />)
            : null);
};

SentEmailField.defaultProps = {
    addLabel: true,
};