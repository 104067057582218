import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box} from '@material-ui/core';
import {useDataProvider} from 'react-admin';
import {format, isBefore, startOfMonth, subMonths} from 'date-fns';
import {ResponsiveBar} from '@nivo/bar';
import {usePartialTheme} from '@nivo/core';
import {pt} from 'date-fns/esm/locale'
import CardWithIcon from "./CardWithIcon";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {commonChartOptions} from "./utils";
import Typography from "@material-ui/core/Typography";

export const DonationsChart = () => {
    const dataProvider = useDataProvider();
    const theme = usePartialTheme(commonChartOptions.theme);

    const [donationsData, setDonationsData] = useState([]);

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        dataProvider.create(`donations/monthly/`, {
            data: {
                raw_request: "GET",
            }
        }).then((result) => {
            const start_date = subMonths(startOfMonth(new Date()), 12);
            setDonationsData(JSON.parse(result.data.body).filter(month =>
                isBefore(start_date, new Date(month.month))
            ).map(month =>
                ({
                    ...month,
                    average: month.average.toFixed(2),
                    average_donated: month.average_donated ? month.average_donated.toFixed(2) : 0,
                    date: format(new Date(month.month), 'MMM', {locale: pt}),
                })
            ));
        });
    }, [dataProvider, setDonationsData]);


    if (!donationsData) return null;

    const TooltipLine = ({name, value, id, currentValue}) => (
        <>
            <Typography color={currentValue === id ? "secondary" : "textPrimary"} >{name}: <strong>{`${value}`}€</strong></Typography>
        </>
    );

    const tooltipFields = [
        {
            id: 'total',
            name: 'Valor total donativos',
            color: '#7570b3'
        },
        {
            id: 'average',
            name: 'Valor médio por aluno',
            color: '#e7298a'
        },
        {
            id: 'average_donated',
            name: 'Valor médio doado',
            color: '#66a61e'
        },
    ]

    const tooltip = data => (
        <div style={theme.tooltip.container}>
            <span><strong>{format(new Date(data.data.month), 'MMMM yyyy', {locale: pt})}</strong></span>
            {
                tooltipFields.map(field =>
                    <div style={theme.tooltip.basic} key={field.id}>
                        <TooltipLine name={field.name} value={data.data[field.id]} id={field.id} currentValue={data.id}/>
                    </div>
                )
            }
            <span>
                {data.data.donated} de {data.data.confirmed} aluno(s) fizeram um donativo ({(data.data.donated/data.data.confirmed * 100).toFixed(1)}%)
            </span>
        </div>
    );

    return (
        <>
            <CardWithIcon
                icon={AttachMoneyIcon}
                to={""}
                title="Donativos"
                onClick={() => setVisible(!visible)}
            />
            {visible &&
                <Box height={300}>
                    <ResponsiveBar
                        {...commonChartOptions}
                        data={donationsData}
                        indexBy="date"
                        keys={['total']}
                        groupMode="grouped"
                        colors={tooltipFields.map(field => field.color)}
                        tooltip={tooltip}
                    />
                </Box>
            }
        </>
    );
};
