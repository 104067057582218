import {
    ArrayField,
    Datagrid,
    EditButton,
    List,
    ReferenceField,
    SimpleList,
    SingleFieldList,
    TextField,
    FunctionField
} from 'react-admin';
import {Chip} from "@material-ui/core";
import * as React from "react";
import BigPagination from "../components/BigPagination";
import {useMediaQuery} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {getIterationName} from "../iterations/utils";

const ResourceList = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} pagination={<BigPagination />}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => <Typography variant="body2">
                        {`${record.program.slug} / ${record.name}`}</Typography>}
                    secondaryText={record => `${record.slug} (${record.short_slug})`}
                    tertiaryText={record => `${record.iterations.length} iterações`}
                    linkType="show"
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    <TextField source="program.slug" sortBy="program/slug" />
                    <TextField source="slug" />
                    <TextField source="short_slug" />
                    <ArrayField source="iterations" sortable={false}>
                        <SingleFieldList linkType={false}>
                            <ReferenceField source="id" reference="iterations" link="show">
                                <FunctionField render={record => <Chip label={getIterationName(record)} style={{margin: '5px 5px 5px 0px'}}/>}/>
                            </ReferenceField>
                        </SingleFieldList>
                    </ArrayField>
                    {permissions && permissions.isAdmin() &&
                        <EditButton />
                    }
                </Datagrid>
            )}
        </List>
    );
};

export default ResourceList;