import {
    ArrayInput,
    BooleanInput,
    Create,
    Edit,
    FormDataConsumer,
    FormTab,
    ReferenceInput,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    required,
    regex,
    number,
    minValue,
    useRedirect,
    useEditController,
    useCreateController
} from 'react-admin'
import {Grid} from '@material-ui/core';
import {useState} from 'react';
import {HostedImageInput} from '../components/HostedImageFieldInput';
import {courseOption, edxOption, getIterationName} from "./utils";
import {JSONInput} from "../components/JsonFieldInput";
import {DateInput, DateTimeInput} from '../components/date-time-pickers/';
import ForumReferenceInput from "./iteration_buttons/forum_button/ForumReferenceInput";
import DiscordReferenceInput from "./iteration_buttons/discord_button/DiscordReferenceInput";
import ToolbarWithoutDelete from '../components/ToolbarWithoutDelete';
import { getApplicationSpecName } from '../application_specs/utils';
import ApplicationSpecSelectionDialog from './ApplicationSpecSelectionDialog';
import applicationSchema from '../application_specs/schemas/applicationSchema';
import titleValueSchema from '../application_specs/schemas/titleValueSchema';

const IterationTitle = ({ record }) => {
    return <span>A editar {getIterationName(record, true, false)}</span>;
};

const IterationShiftInput = () => (
    <Grid container spacing={1} style={{width: "100%"}}>
        <Grid item xs={1}>
            <TextInput source="shift" validate={validateShift} />
        </Grid>
        <Grid item>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <TextInput source="shift_schedule" {...rest} disabled={!formData.shift} />
                )}
            </FormDataConsumer>
        </Grid>
    </Grid>
)

const validateIterationName = [required(), regex(/^20\d{2}-\d{2}( .+)?$/, 'Tem de estar no formato YYYY-## str?')];
const validateOldAsoIterationName = [required(), regex(/^20\d{2}-\d{2}$/, 'Tem de estar no formato YYYY-##')];
const validateShift = [number(), minValue(1)];


const editionForm = (edit, applicationSpec) => {
    const program = applicationSpec?.program;
    const isIterationScoped = applicationSpec?.application_scope === "iteration";
    const isOldAso = program?.slug === "aso" && applicationSpec.slug === "pre-programa";
    
    return (<TabbedForm toolbar={<ToolbarWithoutDelete />}>
        <FormTab label="Geral">
            {edit && <TextInput fullWidth disabled source="id" />}
            <TextInput
                fullWidth
                disabled
                source="application_spec_id"
                defaultValue={applicationSpec?.id}
                format={_ => getApplicationSpecName(applicationSpec)}
            />
            <ReferenceInput perPage={1000} 
                fullWidth source="course_id" 
                reference="courses" 
                filter={{"program_id": program?.id}}
            >
                <SelectInput optionText={courseOption} validate={required()} />
            </ReferenceInput>
            <TextInput 
                fullWidth 
                source="iteration_name" 
                validate={isOldAso ? validateOldAsoIterationName : validateIterationName} 
                helperText={isOldAso ? 
                    "No formato YYYY-##. Exemplo: '2021-03'" : 
                    "No formato YYYY-## str?. Exemplo: '2021-03' ou '2021-03 1º Semestre'"
                } 
            />
            {isOldAso && <TextInput fullWidth source="old_id" />}
            {!isIterationScoped && <IterationShiftInput />}
            <HostedImageInput source="image_id" accept="image/*" validate={isOldAso ? required() : undefined}/>
        </FormTab>
        <FormTab label="Calendário">
            <DateInput fullWidth source="start_date" validate={required()} helperText="Data em que abre o LMS e não a data da primeira sessão síncrona."/>
            <DateInput fullWidth source="end_date" validate={required()}/>
            {isIterationScoped && <DateTimeInput fullWidth source="applications_start_date" />}
            {isIterationScoped && <DateTimeInput fullWidth source="applications_end_date" />}
        </FormTab>
        <FormTab label="Detalhes">
            <TextInput fullWidth source="scholarity" validate={required()} />
            <TextInput fullWidth source="location" validate={required()} helperText={"Deve ser 'online' ou corresponder a um dos valores do ficheiro .json de configuração do site das candidaturas. Valores comuns: 'ist' (para Instituto Superior Técnico) e 'champalimaud' (para Centro Champalimaud)."}/>
            <FormDataConsumer fullWidth>
                {({ formData, ...rest }) => formData.location !== "online" &&
                    <TextInput fullWidth source="location_text" {...rest} helperText={"Descrição mais específica da localização, que será enviada por email. Exemplo: 'na Sala 4.35 do Departamento de Matemática do Instituto Superior Técnico'"} />
                }
            </FormDataConsumer>
            <TextInput multiline fullWidth source="short_description"/>
            <ArrayInput source="description" validate={required()}
                        helperText="Este campo (descrição) aceita markdown. Ver mais: https://commonmark.org/help/">
                <SimpleFormIterator>
                    <TextInput multiline fullWidth source=""/>
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="topics">
                <SimpleFormIterator>
                    <TextInput fullWidth source=""/>
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput multiline fullWidth source="additional_info" />
            <JSONInput source="side_info" schema={titleValueSchema}/>
            <JSONInput source="faq" />
        </FormTab>
        {isIterationScoped && 
            <FormTab label="Formato Candidatura">
                <JSONInput source="application_questions" schema={applicationSchema} />
            </FormTab>
        }
        {
            edit &&
            <FormTab label="Plataformas">
                <ForumReferenceInput
                    source="forum_category_id"
                    reference="forums"
                    allowEmpty
                    perPage={10000}
                />
                <DiscordReferenceInput
                    source="discord_server_id"
                    reference="discords"
                    allowEmpty
                    perPage={10000}
                />
                <ReferenceInput perPage={1000} source="edx_id" reference="edx_courses" allowEmpty>
                    <SelectInput optionText={edxOption}/>
                </ReferenceInput>
                <BooleanInput fullWidth source="work_before_first" />
            </FormTab>
        }
    </TabbedForm>
    );
};


export const IterationEdit = props => {
    const controllerProps = useEditController(props);
    const { record } = controllerProps;
    const applicationSpec = record?.application_spec;

    return (
        <Edit {...props} title={<IterationTitle/>}>
            {editionForm(true, applicationSpec)}
        </Edit>
    );
}

export const IterationCreate = props => {
    const redirect = useRedirect();
    const controllerProps = useCreateController(props);
    const { record } = controllerProps; // record exists only when cloned
    const [applicationSpec, setApplicationSpec] = useState(record?.application_spec);

    return applicationSpec ? (
            <Create {...props}>
                {editionForm(false, applicationSpec)}
            </Create>
        ) : (
            <ApplicationSpecSelectionDialog
                open={true}
                onClose={() => redirect('/iterations')}
                onConfirm={selected => setApplicationSpec(selected)}
            />
        );
};
