import {EmailField, Show, SimpleShowLayout, TextField} from 'react-admin';
import * as React from "react";
import {SentEmailField} from "./SentEmailField";
import LcDateField from "../components/LcDateField";

const ResourceShow = props => (
    <Show
        {...props}
        component="div"
    >
        <SimpleShowLayout>
            <LcDateField source="sent_at" showTime={true}/>
            <TextField source="subject" />
            <EmailField source="to" />
            <EmailField source="cc" />
            <SentEmailField source="message"/>
        </SimpleShowLayout>
    </Show>
);

export default ResourceShow;