import PeopleIcon from '@material-ui/icons/People';
import UsersList from "./list";
import UsersShow from "./show";
import UsersEdit from "./edition";

const Users = {
    name: "users",
    list: UsersList,
    edit: UsersEdit,
    show: UsersShow,
    icon: PeopleIcon,
};

export default Users;
