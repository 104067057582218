import * as React from 'react';
import { useRecordContext } from 'react-admin';

const Traceback = ({ source}) => {
    const record = useRecordContext();
    let value = record && record[source];

    if (value == null) {
        return null;
    }

    return (
        <pre>
            {value}
        </pre>
    );
};

Traceback.defaultProps = {
    addLabel: true,
};

export default Traceback;