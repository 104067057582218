import React from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useRedirect, CloneButton } from 'react-admin';
import { stringify } from 'query-string';

const omitId = ({ id, ...rest }) => rest;

const CloneButtonFetched = (props) => {
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const { record, resource, basePath } = props;

    const handleClick = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        try {
            const { data } = await dataProvider.getOne(resource, { id: record.id });
            const pathname = basePath ? `${basePath}/create` : `/${resource}/create`;
            const search = stringify({
                source: JSON.stringify(omitId(data)),
            });
            const url = `${pathname}?${search}`;
            redirect(url, { state: { _scrollToTop: true } });
        } catch (error) {
            console.error(error);
        }
    };

    return <CloneButton {...props} onClick={handleClick} />;
};

CloneButtonFetched.propTypes = {
    record: PropTypes.object,
    resource: PropTypes.string,
    basePath: PropTypes.string,
};

export default CloneButtonFetched;
