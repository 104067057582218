import {useGetList, useRecordContext, LinearProgress} from 'react-admin';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Grid from "@material-ui/core/Grid";

const AttendancesField = () => {
    const record = useRecordContext();

    const { data, ids, loading, error } = useGetList(
        'session_participants',
        { page: 1, perPage: 1000 },
        { field: 'session_number', order: 'ASC' },
        {user_id: record["user_id"], course_iter_id: record["course_iter_id"]}
    );

    if (loading) { return <LinearProgress />; }
    if (error) { return <p>ERROR</p>; }

    return (
        <Grid item container>
            {ids.map(id =>
                <div key={id}>
                    {data[id] && data[id].attended_session_id &&
                        <CheckIcon />
                    }
                    {data[id] && !data[id].attended_session_id &&
                        <CloseIcon />
                    }
                </div>
            )}
        </Grid>
    );
};

AttendancesField.defaultProps = {
    addLabel: true,
    label: "Presenças"
};

export default AttendancesField;