import {Show, SimpleShowLayout, TextField} from 'react-admin';
import * as React from "react";
import TaskName from "./TaskName";
import Traceback from "./Traceback";
import LcDateField from "../components/LcDateField";

const ResourceShow = props => (
    <Show
        {...props}
        component="div"
    >
        <SimpleShowLayout>
            <TaskName source="name" />
            <TextField source="status" />
            <LcDateField source="date_done" showTime={true}/>

            <TextField source="args" />
            <TextField source="kwargs" />
            <Traceback source="traceback" />
        </SimpleShowLayout>
    </Show>
);

export default ResourceShow;