import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    EmailField, ChipField,
    FilterList, Loading,
    FilterListItem, useGetList, FilterLiveSearch
} from 'react-admin';
import * as React from "react";
import CourseIcon from '@material-ui/icons/School';
import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';

export const CourseIterationsField = ({ record, source }) => {
    const counts = {};

    if(record && record[source])
        record[source].forEach(course_id => {
            if (!(course_id in counts))
               counts[course_id] = 0;
            counts[course_id]++;
        });

    const CourseChip = ({course_id, source, record, ...props}) => {
        return (
            <ChipField record={{value: record[source] + " (" + counts[course_id] + ")"}} source="value" {...props}/>
        );
    };

    return record ? [...new Set(record[source])].map(course_id => {
        return (
            <ReferenceField key={course_id} record={{id: course_id}} source="id" reference="courses">
                <CourseChip source="name" course_id={course_id} />
            </ReferenceField>
        )
    }) : null;
};

const CourseFilter = () => {
    const { data, ids, loading, error } = useGetList(
        'courses',
        {perPage: 1000, page: 1},
        { field: 'name', order: 'ASC' }
    );
    if (loading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }


    return <FilterList label="resources.staff_users_contacts.fields.courses" icon={<CourseIcon/>}>
            {ids.map(id => (
                <FilterListItem
                    label={data[id].name}
                    key={id}
                    value={{course: id}}
                />
            ))}
        </FilterList>;
};

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const FilterSidebar = () => (
    <Card>
        <CardContent>
            <FilterLiveSearch source="q" />
            <CourseFilter />
        </CardContent>
    </Card>
);

const ResourceList = props => (
    <List {...props} sort={{ field: 'name', order: 'ASC' }} perPage={25} bulkActionButtons={false} aside={<FilterSidebar />}>
        <Datagrid rowClick={(id) => "users/" + id + "/show"}>
            <TextField source="name" />
            <EmailField source="email" />
            <TextField source="phone" />
            <CourseIterationsField source="courses" />
        </Datagrid>
    </List>
);


export default ResourceList;