import {DateTimePicker, LocalizationProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {TextField} from "@material-ui/core";
import * as React from "react";

import "moment/locale/pt";
moment.locale("pt");

const MUIDateTimePicker = props => (
    <LocalizationProvider dateAdapter={MomentUtils} dateLibInstance={moment} >
        <DateTimePicker
            variant="inline"
            renderInput={(props) => <TextField {...props} margin="normal"
                                               variant="filled"/>}
            {...props}
        />
    </LocalizationProvider>
);

export default MUIDateTimePicker;