import * as React from 'react';
import {useEffect, useState} from 'react';
import {SimpleList, useGetList,} from 'react-admin';

import iterations from '../iterations';
import {CourseAvatar} from "../iterations/CourseAvatar";
import CardWithIcon from "./CardWithIcon";
import { getIterationName } from '../iterations/utils';

export const ActiveCourses = () => {
    const { data, ids: unfilteredIds, loaded } = useGetList(
        'iterations',
        { page: 1, perPage: 50 },
        { field: 'start_date', order: 'DESC' },
        {},
    );

    const [ids, setIds] = useState([]);

    useEffect(() => {
        setIds(unfilteredIds.filter(id => data[id] && data[id].calendar_status === 'running'));
    }, [unfilteredIds, data]);

    return (
        <>
            <CardWithIcon
                to="/iterations"
                icon={iterations.icon}
                title="Cursos Ativos"
                subtitle={ids.length}
            >
                <SimpleList
                    basePath="/iterations"
                    linkType="show"
                    ids={ids}
                    data={data}
                    total={ids.length}
                    loaded={loaded}
                    primaryText={record => getIterationName(record, true)}
                    secondaryText={record =>
                        `${record.students_nr} alunos`
                    }
                    leftAvatar={record => <CourseAvatar record={record}/>}
                />
            </CardWithIcon>
        </>
    );
};
