import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    Button,
    SaveButton,
    useCreate,
    useNotify,
    FormWithRedirect, ReferenceInput, SelectInput
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {forumOption} from "../../utils";

const ForumCreateButton = ({ onChange, record }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { loading }] = useCreate('forums');
    const notify = useNotify();
    const form = useForm();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        create(
            { payload: { data: {...values, iteration_id: record.id}} },
            {
                onSuccess: ({ data }) => {
                    setShowDialog(false);
                    // Update the iteration form to target the newly created forum
                    // Updating the ReferenceInput value will force it to reload the available forums
                    onChange();
                    form.change('forum_category_id', data.id);
                    notify("resources.forums.created")
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    return (
        <>
            <Button onClick={handleClick} label="ra.action.create">
                <IconContentAdd />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Duplicar um fórum"
            >
                <DialogTitle>Criar o fórum para este curso</DialogTitle>

                <DialogContent>
                <span>Este processo poderá demorar alguns minutos. É favor não mudar de página ou fechar esta caixa enquanto o fórum estiver a ser criado.</span>
                </DialogContent>
                <FormWithRedirect
                    resource="forums"
                    save={handleSubmit}
                    render={({
                                 handleSubmitWithRedirect,
                                 pristine,
                                 saving
                             }) => (
                        <>
                            <DialogContent>

                                <ReferenceInput perPage={1000} label="Fórum a duplicar" source="old_forum_id" reference="forums" fullWidth
                                                validate={required()} defaultValue={record.forum_category_id ? record.forum_category_id : null}>
                                    <SelectInput optionText={forumOption}/>
                                </ReferenceInput>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}

export default ForumCreateButton;
