import {fetchUtils} from 'ra-core';
import {stringify} from 'query-string';


const DataProvider = (getAccessTokenSilently) => {
    const countHeader = 'Content-Range-Total';

    const httpClient = async (path, options) => {
        options = options || {};
        options.headers = new Headers({ Accept: 'application/json'});
        const token = await getAccessTokenSilently();
        if (token) {
            options.headers.set('Authorization', `Bearer ${token}`);
        }
        return fetchUtils.fetchJson(process.env.REACT_APP_API_URL + path, options);
    };


    const handleGet = async (resource, query) => {
        const url = `${resource}/?${stringify(query)}`;

        return await httpClient(url).then(({ headers, json }) => {
            if (!headers.has(countHeader)) {
                throw new Error(
                    `The ${countHeader} header is missing in the HTTP Response. The REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                );
            }
            return {
                data: parseReturnData(resource, json),
                total: parseInt(headers.get(countHeader)),
            };
        });
    }

    const handleImage = async (resource_name, image) => {
        if (image == null || typeof(image) == 'string')
            return image;

        const formData = new FormData();
        formData.append('file', image.rawFile);

        return await httpClient(`files/admin/${resource_name}`, {
            method: "POST",
            body: formData,
        }).then(({ json }) => (json.id));
    };

    const parseBody = async (resource, data, create=false) => {
        let excluded = [];
        if (resource === "iterations"){
            excluded = ["participants_nr", "course", "id", "applications_nr", "staff_users_nr", "calendar_status", 
                "students_nr", "application_spec", "sessions"];
            if(!create) excluded.push("staff_users");
            data['image_id'] = await handleImage('iteration_image', data['image_id']);
        }
        else if (resource === "courses")
            excluded = ["iterations", "id", "program"];
        else if (resource === "staff_users") {
            excluded = ["course_iteration", "id", "user"];
            const prev_image = data['image_id'];
            data['image_id'] = await handleImage('staff_user', data['image_id']);
            // if this was a manual image upload, change pending one too
            if (prev_image !== data['image_id'])
                data['image_id_pending'] = data['image_id'];
        }
        else if (resource === "users")
            excluded = ["applications_nr", "auth_userid", "email", "id", "name", "participations_nr", "staff_roles_nr"]
        else if (resource === "applications" || resource === 'donations')
            excluded = ["course_iteration", "id", "user"];
        else if (resource === "application_specs")
            excluded = ["id", "program", "submissions", "iterations"];
        else if (resource === "sessions")
            excluded = ["course_iteration"];
        return JSON.stringify(Object.keys(data).filter(key => !excluded.includes(key)).reduce((obj, key) =>
            {
                obj[key] = data[key];
                return obj;
            }, {}
        ));
    }

    const compositeIdCourseUser = record => record.course_iter_id + "/" + record.user_id;
    const compositeIdSessionParticipant = record => record.session_id + "/" + record.user_id;
    const compositeIdApplicationSpecUser = record => record.application_spec_id + "/" + record.user_id;

    const compositeIds = {
        "staff_users": compositeIdCourseUser,
        "applications": compositeIdCourseUser,
        "donations": compositeIdCourseUser,
        "participants": compositeIdCourseUser,
        "session_participants": compositeIdSessionParticipant,
        "application_submissions": compositeIdApplicationSpecUser,
    };

    const parseReturnData = (resource, json) => {
        if (resource in compositeIds){
            if (Array.isArray(json)) {
                for (let i = 0; i < json.length; i++){
                    const id = compositeIds[resource](json[i]);
                    if (id)
                        json[i].id = id;
                }
            } else {
                const id = compositeIds[resource](json);
                if (id)
                    json.id = id;
            }
        }
        return json;
    };

    const raw_request = async (method, path, data) => {
        let body = {};
        if (method !== "GET")
            body = {body: JSON.stringify(data)};
        return await httpClient(path, {
            method: method,
            ...body,
        }).then((res) => ({data: {...res, id: 1}}))
    };

    return {
        httpClient: httpClient,

        getList: async (resource, params) => {
            const {page, perPage} = params.pagination;
            const {field, order} = params.sort;
            const query = {
                sort: [field, order],
                page: page,
                per_page: perPage,
                filter: JSON.stringify(params.filter)
            };
            return await handleGet(resource, query);
        },

        getOne: async (resource, params) =>
            await httpClient(`${resource}/${params.id}`).then(({json}) => ({
                data: parseReturnData(resource, json),
            })),

        getMany: async (resource, params) => {
            const query = {
                filter: JSON.stringify({id: params.ids}),
            };
            const url = `${resource}/?${stringify(query)}`;
            return await httpClient(url).then(({json}) => ({data: parseReturnData(resource, json)}));
        },

        getManyReference: async (resource, params) => {
            const {page, perPage} = params.pagination;
            const {field, order} = params.sort;

            const query = {
                sort: [field, order],
                page: page,
                per_page: perPage,
                filter: JSON.stringify({
                    ...params.filter,
                    [params.target]: params.id,
                })
            };

            return await handleGet(resource, query);
        },

        update: async (resource, params) =>
            await httpClient(`${resource}/${params.id}`, {
                method: 'PUT',
                body: await parseBody(resource, params.data),
            }).then(({json}) => ({data: parseReturnData(resource, json)})),

        updateMany: (resource, params) =>
            Promise.all(
                params.ids.map(async id =>
                    await httpClient(`${resource}/${id}`, {
                        method: 'PUT',
                        body: await parseBody(resource, params.data),
                    })
                )
            ).then(responses => ({data: responses.map(({json}) => parseReturnData(resource, json).id)})),

        create: async (resource, params) => {
            if (params.data.raw_request){
                const method = params.data.raw_request;
                delete params.data.raw_request;
                return await raw_request(method, resource, params.data);
            }

            return await httpClient(`${resource}/`, {
                method: 'POST',
                body: await parseBody(resource, params.data, true),
            }).then(({json}) => ({
                data: {...params.data, id: parseReturnData(resource, json).id},
            }))
        },

        delete: async (resource, params) =>
            await httpClient(`${resource}/${params.id}`, {
                method: 'DELETE',
                headers: new Headers({
                    'Content-Type': 'text/plain',
                }),
            }).then(({json}) => ({data: json})),

        deleteMany: (resource, params) =>
            Promise.all(
                params.ids.map(async id =>
                    await httpClient(`${resource}/${id}`, {
                        method: 'DELETE',
                        headers: new Headers({
                            'Content-Type': 'text/plain',
                        }),
                    })
                )
            ).then(responses => ({
                data: responses.map(({json}) => json.id),
            })),
    }
};

export default DataProvider;
