import AssignmentIcon from '@material-ui/icons/AssignmentTurnedIn';
import ApplicationList from "./list";
import ApplicationShow from "./show";

const Applications = {
    name: "applications",
    list: ApplicationList,
    show: ApplicationShow,
    icon: AssignmentIcon,
};

export default Applications;