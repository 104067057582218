import AssignmentIcon from '@material-ui/icons/Assignment';
import ApplicationSpecList from "./list";
import ApplicationSpecShow from './show';
import { ApplicationSpecCreate, ApplicationSpecEdit } from './edition';

const ApplicationSpecs = {
    name: "application_specs",
    list: ApplicationSpecList,
    edit: ApplicationSpecEdit,
    create: ApplicationSpecCreate,
    show: ApplicationSpecShow,
    icon: AssignmentIcon,
};

export default ApplicationSpecs;
