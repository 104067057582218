import * as React from 'react';
import PropTypes from 'prop-types';
import DoneIcon from '@material-ui/icons/Done';

import {Button, CRUD_UPDATE_MANY, useNotify, useRefresh, useUnselectAll, useUpdateMany,} from 'react-admin';

const noSelection = [];

const BulkValidateButton = ({
    selectedIds = noSelection,
}) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll('donations');

    const [approve, { loading }] = useUpdateMany(
        'donations',
        selectedIds,
        { 'donation_validated': true },
        {
            action: CRUD_UPDATE_MANY,
            undoable: true,
            onSuccess: () => {
                notify(
                    'resources.donations.validated_success',
                    'info',
                    {},
                    true
                );
                refresh();
                unselectAll();
            },
            onFailure: () => {
                notify(
                    'resources.donations.validated_error',
                    'warning'
                );
            },
        }
    );

    return (
        <Button
            label="resources.donations.validate_button"
            onClick={approve}
            disabled={loading}
        >
            <DoneIcon />
        </Button>
    );
};

BulkValidateButton.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BulkValidateButton;
