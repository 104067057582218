import * as React from 'react';
import {useState} from 'react';

import {Button, useDataProvider, useNotify, useRecordContext,} from 'react-admin';
import DraftsIcon from "@material-ui/icons/Drafts";

const SendLoginsButton = () => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const record = useRecordContext();

    const submit = async () => {
        setLoading(true);
        dataProvider.create(`course_management/send_student_logins/`, {
            data: {
                raw_request: "POST",
                participants: [{
                    course_iter_id: record.course_iter_id,
                    user_id: record.user_id
                }]
            }
        }).then(() => {
            notify("resources.applications.send_student_logins_success");
        }).catch(error =>
            notify(error.message, 'error')
        ).finally(() =>
            setLoading(false)
        )
    }

    const invalidSelection = !record || !(record.accepted && record.confirmed);

    return (
        <Button
            label="resources.applications.send_student_logins_button"
            onClick={submit}
            disabled={loading || invalidSelection}
        >
            <DraftsIcon />
        </Button>
    );
};

export default SendLoginsButton;
