import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment'
import ptLocale from '@fullcalendar/core/locales/pt';
import styled from "@emotion/styled";
import {linkToRecord, useListContext} from 'react-admin';
import {add, set, sub} from "date-fns";
import {timezone} from "../App";

const getCalendarFilters = (dateSource, dateInfo) => {
    const dateStartGte = `${dateSource}_gte`, dateStartLte = `${dateSource}_lte`;
    return {
        [dateStartGte]: sub(dateInfo.start, { months: 1 }).toISOString(),
        [dateStartLte]: add(dateInfo.end, { months: 2 }).toISOString(),
    };
}

export const getDefaultCalendarFilters = (dateSource) => {
    const now = set(new Date(), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });

    const dateInfo = {
        start: sub(now, { months: 1 }),
        end: add(now, { months: 1 }),
    };

    return getCalendarFilters(dateSource, dateInfo);
}

const Calendar = (props) => {
    const {
        convertToEvent,
        linkType = 'edit',
        dateSource,
    } = props;

    const { basePath, data, ids, loaded, filterValues, setFilters, displayedFilters } = useListContext(props);

    const eventData = loaded ? ids.map(id => data[id]) : [];
    const events = eventData.map(event => convertToEvent ? convertToEvent(event) : event).map(event => ({
        ...event,
        url: linkType === 'edit' || linkType === true ? `#${linkToRecord(basePath, event.id)}` : (
            linkType === 'show' ? `#${linkToRecord(basePath, event.id)}/show` : null
        )
    }));

    const datesSet = (dateInfo) => {
        setFilters({
            ...filterValues,
            ...getCalendarFilters(dateSource, dateInfo)
        }, displayedFilters);
    };

    return (
        <StyleWrapper>
            <FullCalendar
                timeZone={timezone}
                plugins={[ dayGridPlugin, timeGridPlugin, momentPlugin ]}
                initialView="dayGridMonth"
                locale={ptLocale}
                headerToolbar={{
                    start: 'prev,next today', // will normally be on the left. if RTL, will be on the right
                    center: 'title',
                    end: 'dayGridMonth,timeGridWeek,timeGridDay' // will normally be on the right. if RTL, will be on the left
                }}
                datesSet={datesSet}
                events={events}
            />
        </StyleWrapper>
    )
};

export default Calendar;

export const StyleWrapper = styled.div`
  .fc {
    color: #fff;
    padding: 1em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  .fc-toolbar-title {
    font-size: 1.5rem;
    font-weight: 400;
  }
  .fc-event {
    color: #fff
  }
  .fc-button {
    color: #fff;
    font-size: 0.875rem;
    font-weight: 500;
    border-color: rgba(255, 255, 255, 0.12);
    text-transform: uppercase;
    background-color: #424242;
  }
  .fc-button-primary:disabled {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
    background-color: rgba(255, 255, 255, 0.16);
  }
  .fc-button-group .fc-button {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
  }
  .fc-button:hover {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .fc-button-primary:not(:disabled):active {
    color: #fff;
    background-color: #fff;
  }
  .fc-button-group .fc-button-primary:not(:disabled):active {
    border-color: rgba(255, 255, 255, 0.12);
  }
  .fc-button-primary:not(:disabled).fc-button-active {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.16);
  }
  .fc-button-group .fc-button-primary:not(:disabled).fc-button-active {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.12);
  }
  .fc-button-primary:not(:disabled):focus {
    box-shadow: none;
  }
  .fc-button-primary:not(:disabled):active:focus {
    box-shadow: none;
  }
  .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
  }
  .fc-popover {
    background-color: #424242;
  }
  .fc-button-primary:not(:disabled).fc-button-active:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .fc-button-primary:disabled:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`