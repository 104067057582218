import * as React from "react";
import {Button, useNotify, useRecordContext, useRefresh} from "react-admin";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import MUIButton from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import RemoveIcon from '@material-ui/icons/Remove';
import PersonIcon from '@material-ui/icons/Person';
import { useDataProvider } from 'react-admin';
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

export const AddStaffUserButton = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();

    const [open, setOpen] = React.useState(false);
    const [selects, setSelects] = React.useState({
        add_to_page: true,
        add_to_platforms: true
    });

    const [pageRoles, setPageRoles] = React.useState({
        instrutor: true,
        autor: false,
        coordenador: false,
        apoio: false
    });

    const pageRolesList = [
        "autor", "instrutor", "coordenador", "apoio"
    ]

    const [discordRoles, setDiscordRoles] = React.useState({
        autor: false,
        instrutor: true,
        admin: false,
        visitante: false
    });

    const discordRolesList = [
        "autor", "instrutor", "admin", "visitante"
    ]

    const [users, setUsers] = React.useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        dataProvider.create(`course_management/staff_members/${record.id}`, {
            data: {
                raw_request: "POST",
                users: users,
                add_to_page: selects.add_to_page,
                add_to_platforms: selects.add_to_platforms,
                page_roles: pageRolesList.filter(role => pageRoles[role]),
                discord_roles: discordRolesList.filter(role => discordRoles[role])
            }
        }).then(() => {
            notify("resources.iterations.added_to_team");
            refresh();
        }).catch(error =>
            notify(error.message, 'error')
        ).finally(() => {
            setUsers([]);
            setOpen(false);
        });
    };

    const handleChange = (event) => {
        setSelects({ ...selects, [event.target.name]: event.target.checked });
    };

    const [emailInputValue, setEmailInputValue] = React.useState("");
    const [nameInputValue, setNameInputValue] = React.useState("");
    const [selectedContact, setSelectedContact] = React.useState(null);
    const [contactOptions, setContactOptions] = React.useState([]);

    const onNameInputChange = (event, newInputValue) => {
        setNameInputValue(newInputValue);
    }

    const onContactSelect = (event, newValue) => {
        setContactOptions(newValue ? [newValue, ...contactOptions] : contactOptions);
        setSelectedContact(newValue);
        if (newValue) {
            setNameInputValue(newValue.name);
            setEmailInputValue(newValue.email);
        }
    };

    const onEmailInputChange = (event, newInputValue) => {
        setEmailInputValue(newInputValue);
    };

    // name search
    React.useEffect(() => {
        let active = true;

        if (nameInputValue.length < 3) {
            setContactOptions(selectedContact ? [selectedContact] : []);
            return undefined;
        }

        dataProvider.getList(`staff_users_contacts`, {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'name', order: 'ASC' },
            filter: {"q": nameInputValue}
        }).then(results => {
            if (active) {
                let newOptions = [];

                if (selectedContact) {
                    newOptions = [selectedContact];
                }

                if (results && results.data) {
                    newOptions = [...newOptions, ...results.data];
                }

                setContactOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [selectedContact, nameInputValue, dataProvider]);

    // email search
    React.useEffect(() => {
        let active = true;

        if (emailInputValue.length < 3) {
            setContactOptions(selectedContact ? [selectedContact] : []);
            return undefined;
        }

        dataProvider.getList(`staff_users_contacts`, {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'name', order: 'ASC' },
            filter: {"q": emailInputValue}
        }).then(results => {
            if (active) {
                let newOptions = [];

                if (selectedContact) {
                    newOptions = [selectedContact];
                }

                if (results && results.data) {
                    newOptions = [...newOptions, ...results.data];
                }

                setContactOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [selectedContact, emailInputValue, dataProvider]);

    return (
        <div className={classes.root}>
            <Button
                label="resources.iterations.add_to_team_button"
                onClick={handleClickOpen}
            >
                <PersonAddIcon />
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Adicionar à Equipa</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Os utilizadores abaixo serão adicionados à página do curso e/ou às várias plataformas. Para cada utilizador é necessário indicar nome e email.
                    </DialogContentText>

                    <FormControlLabel
                        control={<Checkbox checked={selects.add_to_page} onChange={handleChange} name="add_to_page" />}
                        label="Adicionar à pagina do curso"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selects.add_to_platforms} onChange={handleChange} name="add_to_platforms" />}
                        label="Dar acesso às plataformas"
                    />
                    <FormControl component="fieldset" className={classes.formControl}>

                        <FormLabel component="legend">Tipo de contributos</FormLabel>
                        <FormGroup>
                            {
                                pageRolesList.map(role => (
                                    <FormControlLabel
                                        key={role}
                                        control={<Checkbox checked={pageRoles[role]} onChange={event => setPageRoles({...pageRoles, [role]: event.target.checked})} color="primary"/>}
                                        label={role}
                                        disabled={!selects.add_to_page}
                                    />
                                ))
                            }
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" className={classes.formControl}>

                        <FormLabel component="legend">Role discord</FormLabel>
                        <FormGroup>
                            {
                                discordRolesList.map(role => (
                                    <FormControlLabel
                                        key={role}
                                        control={<Checkbox checked={discordRoles[role]} onChange={event => setDiscordRoles({...discordRoles, [role]: event.target.checked})} color="primary"/>}
                                        label={role}
                                        disabled={!selects.add_to_platforms}
                                    />
                                ))
                            }
                        </FormGroup>
                    </FormControl>

                    <Autocomplete
                        freeSolo
                        disableClearable
                        options={contactOptions}
                        onInputChange={onNameInputChange}
                        onChange={onContactSelect}
                        fullWidth
                        inputValue={nameInputValue}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (<span>{option.name} ({option.email})</span>)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Nome"
                                variant="outlined"
                            />
                        )}
                    />
                    <br />
                    <Autocomplete
                        freeSolo
                        disableClearable
                        options={contactOptions}
                        onInputChange={onEmailInputChange}
                        inputValue={emailInputValue}
                        onChange={onContactSelect}
                        fullWidth
                        getOptionLabel={(option) => option.email}
                        renderOption={(option) => (<span>{option.email} ({option.name})</span>)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Email"
                                variant="outlined"
                                type="email"
                            />
                        )}
                    />
                    <MUIButton onClick={() => {
                        setUsers([...users, {name: nameInputValue, email: emailInputValue}]);
                        setNameInputValue("");
                        setEmailInputValue("");
                        setContactOptions([]);
                        setSelectedContact(null);
                    }} color="primary">
                        Adicionar pessoa
                    </MUIButton>

                    <List dense={true}>
                        {users.map((user, index) => (
                            <ListItem key={index}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <PersonIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={user.name}
                                    secondary={user.email}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="remove" onClick={() => {
                                        setUsers(users.filter(item => item.email !== user.email));
                                    }}>
                                        <RemoveIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <MUIButton onClick={handleClose} color="primary">
                        Cancelar
                    </MUIButton>
                    <MUIButton onClick={handleSubmit} color="primary">
                        Submeter
                    </MUIButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};
