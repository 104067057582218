import {Button, useNotify, useRefresh} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import MUIButton from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider } from 'react-admin';
import * as React from "react";
import MergeTypeIcon from '@material-ui/icons/MergeType';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

const BulkCreateSessionsButton = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const [open, setOpen] = React.useState(false);

    const [userEmail, setUserEmail] = React.useState('');
    const [userToMergeEmail, setUserToMergeEmail] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
       dataProvider.create(`users/merge/`, {
            data: {
                raw_request: "POST",
                user_email: userEmail,
                user_to_merge_email: userToMergeEmail
            }
        }).then(() => {
            notify("resources.users.merge_successful");
            refresh();
        }).catch(error =>
            notify(error.message, 'error')
        ).finally(() => {
            setUserEmail('');
            setUserToMergeEmail('');
            setOpen(false);
        });
    };

    return (
        <div className={classes.root}>
            <Button
                label="resources.users.merge_button"
                onClick={handleClickOpen}
            >
                <MergeTypeIcon />
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Fundir (merge) dois utilizadores</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Esta ação transfere toda a atividade do segundo utilizador para o primeiro utilizador, devendo ser usado quando um aluno/instrutor no passado usou vários emails distintos.
                    </DialogContentText>

                    <TextField type="email" fullWidth label="Email do utilizador a manter" value={userEmail} onChange={event => setUserEmail(event.target.value)}/>
                    <br/>
                    <TextField type="email" fullWidth label="Email do utilizador a fundir" value={userToMergeEmail} onChange={event => setUserToMergeEmail(event.target.value)}/>
                </DialogContent>
                <DialogActions>
                    <MUIButton onClick={handleClose} color="primary">
                        Cancelar
                    </MUIButton>
                    <MUIButton onClick={handleSubmit} color="primary">
                        Submeter
                    </MUIButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default BulkCreateSessionsButton;