import * as React from 'react';
import {Box, useMediaQuery} from '@material-ui/core';

import {Redirect} from 'react-router';

import {ActiveCourses} from "./ActiveCourses";
import {LastApplications} from "./LastApplications";
import {UserCountsChart} from "./UserCountsChart";
import {DonationsChart} from "./DonationsChart";
import {ReferralsChart} from "./ReferralsChart";

export const Dashboard = ({permissions}) => {

    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );

    const styles = {
        flex: { display: 'flex', marginTop: "1em"},
        flexColumn: { display: 'flex', flexDirection: 'column' },
        leftCol: { flex: 3,  marginRight: '1em' },
        rightCol: { flex: 1 },
    };

    const VerticalSpacer = () => <span style={{ height: '1em' }} />;

    if (permissions && !permissions.isAdmin())
        return <Redirect to="/iterations" />

    return permissions && permissions.isAdmin() ? (
        isXSmall ? (
            <>
                <div style={styles.flexColumn}>
                    <VerticalSpacer />
                    <Box>
                        <ActiveCourses />
                    </Box>
                    <VerticalSpacer />
                    <Box>
                        <UserCountsChart />
                    </Box>
                    <VerticalSpacer />
                    <Box>
                        <ReferralsChart />
                    </Box>
                    <VerticalSpacer />
                    <Box>
                        <LastApplications />
                    </Box>
                    <VerticalSpacer />
                    <Box>
                        <DonationsChart />
                    </Box>
                    <VerticalSpacer />
                </div>
            </>
            ) :
        <>
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <UserCountsChart />
                    <ReferralsChart />
                    <DonationsChart />
                </div>
                <div style={styles.rightCol}>
                    <Box>
                        <ActiveCourses />
                    </Box>
                    <Box mt={"1em"}>
                        <LastApplications />
                    </Box>
                </div>
            </div>
        </>
    ) : null;
};
