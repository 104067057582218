import * as React from "react";
import {Admin, Resource} from 'react-admin';
import DataProvider from './dataProvider'
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {Route} from 'react-router-dom';
import moment from 'moment'

import portugueseMessages from './portuguese';
import polyglotI18nProvider from "ra-i18n-polyglot";

import authProvider from "./authProvider";
import {Layout} from "./layout";

/**
 * Resources
 */
import iterations from './iterations';
import courses from './courses';
import staff_users from './staff_users';
import staff_users_contacts from './staff_users_contacts';
import application_specs from './application_specs';
import application_submissions from './application_submissions';
import applications from './applications';
import participants from './participants';
import users from './users';
import sent_emails from './sent_emails';
import tasks from './tasks';
import sessions from './sessions';
import session_participants from './sessions/session_participants';
import donations from './donations';
import {Dashboard} from "./dashboard/Dashboard";
import CoursesPlanner from './courses_planner'

const resourceList = [
    iterations, courses, staff_users, staff_users_contacts, application_specs,
    application_submissions, applications, participants, users, sent_emails, tasks,
    sessions, session_participants, donations
]

const adminOnlyResources = [
    "forums", "discords", "edx_courses", "programs", "application_specs", 
    "application_submissions"
]

export const timezone = 'Europe/Lisbon';

require('moment')
require('moment-timezone')
moment.tz.setDefault(timezone)

const App = () => {
    const {
        getAccessTokenSilently,
        isAuthenticated,
        loginWithRedirect,
        logout,
        user
    } = useAuth0();

    const dataProvider = DataProvider(getAccessTokenSilently);

    const auth0AuthProvider = authProvider({
        dataProvider,
        isAuthenticated,
        loginWithRedirect,
        logout,
        user
    });

    const messages = {
        pt: portugueseMessages,
    };

    const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "pt");

    return (
        <Admin
            dataProvider={dataProvider}
            authProvider={auth0AuthProvider}
            i18nProvider={i18nProvider}
            dashboard={Dashboard}
            layout={Layout}
            customRoutes={[
                <Route path="/courses-planner" component={CoursesPlanner.page} />,
            ]}
            disableTelemetry
        >
            {permissions => [
                resourceList.reduce((arr, resource) => {
                    const viewable = permissions.getAllowedViews(resource);
                    if (Object.keys(viewable).length > 0)
                        arr.push(<Resource name={resource.name} icon={resource.icon} {...viewable} />);
                    return arr;
                }, []),
                permissions.isAdmin() ?
                        adminOnlyResources.map(resourceName =>
                            <Resource name={resourceName} />
                        ) : null
            ]}
        </Admin>
    );
};

export default withAuthenticationRequired(App);
