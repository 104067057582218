import EventIcon from '@material-ui/icons/Event';
import { SessionEdit, SessionCreate } from './edition';
import List from "./list";
import Show from "./show";

const Sessions = {
    name: "sessions",
    list: List,
    edit: SessionEdit,
    create: SessionCreate,
    show: Show,
    icon: EventIcon,
};

export default Sessions;
